<template>
  <ul class="list-group mb-2">
    <li class="list-group-item">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <b class="text-dark">{{ ticket.details.subject }}</b>
          <p class="small text-muted mb-0">
            {{ ticket.details.category_name }}
          </p>
        </div>
        <div class="flex-shrink-0">
          <i class="bi bi-chat-right-text fs-4 text-muted"></i>
        </div>
      </div>
    </li>
  </ul>
  <div class="card mb-2">
    <div class="card-header bg-white">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <b class="small">{{ timeAgo(ticket.details.created_at) }}</b>
          <p class="small text-muted mb-0">
            {{ localMachineTime(ticket.details.created_at) }}
          </p>
        </div>

        <div class="flex-grow-1 me-3 text-end">
          <b class="text-dark">{{ ticket.details.creator_name }}</b>
          <p class="small text-muted mb-0">
            {{ ticket.details.organisation_name }}
          </p>
        </div>
        <div class="flex-shrink-0">
          <img :src="ticket.details.creator_avatar" alt="twbs" class="rounded-circle align-self-center" width="36"
            height="36" />
        </div>
      </div>
    </div>
    <div class="card-body">
      <p class="mb-0 small" v-html="nlToBr(ticket.details.description)"></p>
    </div>
    <ul class="list-group list-group-flush">
      <li v-for="file in ticket.files" v-bind:key="file.id" class="list-group-item">
        <div class="d-flex align-items-center">
          <div class="flex-shrink-0">
            <i v-if="'pdf' == file.extension" class="bi bi-file-pdf fs-4"></i>
            <i v-else-if="'zip' == file.extension" class="bi bi-file-zip fs-4"></i>
            <i v-else-if="
              'png' == file.extension ||
              'jpg' == file.extension ||
              'gif' == file.extension ||
              'jpeg' == file.extension
            " class="bi bi-file-image fs-4"></i>
            <i v-else class="bi bi-file fs-4"></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <b class="text-dark">{{ file.name }}</b>
            <p class="small text-muted mb-0">
              {{ Math.round(file.size / 1024) }} KB
            </p>
          </div>
          <button type="button" class="btn btn-outline-dark" @click="download_file(file.path)">
            Download
          </button>
        </div>
      </li>
    </ul>
  </div>

  <div v-for="comment in ticket.comments" v-bind:key="comment.id" class="card mb-2">
    <div class="card-header bg-info bg-opacity-10">
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0">
          <img :src="comment.user_avatar" alt="twbs" class="rounded-circle align-self-center" width="36" height="36" />
        </div>
        <div class="flex-grow-1 ms-3">
          <b class="text-dark">{{ comment.user_name }}</b>
          <p class="small text-muted mb-0">{{ comment.user_organisation }}</p>
        </div>
        <div class="flex-grow-1 text-end">
          <b class="small">{{ timeAgo(comment.created_at) }}</b>
          <p class="small text-muted mb-0">
            {{ localMachineTime(comment.created_at) }}
          </p>
        </div>
      </div>
    </div>
    <div class="card-body bg-info bg-opacity-10">
      <p class="mb-0 small" v-html="nlToBr(comment.comment)"></p>
    </div>
  </div>
  <div v-if="'closed' !== ticket.details.original_status" class="card mb-2">
    <div class="card-header bg-white">
      <i class="bi bi-chat-right-text me-2"></i>
      New Comment
    </div>
    <div class="card-body">
      <div class="mb-2">
        <textarea v-model="new_comment" class="form-control" rows="5" id="new_comment"></textarea>
      </div>
      <div class="text-end d-flex justify-content-between">
        <div>
          <button type="button" class="btn btn-outline-dark" @click="open_attach_files">
            <i class="bi bi-paperclip"></i> Attach Files
          </button>
          <input type="file" id="fileAttachment" class="d-none" />
        </div>
        <div class="text-end d-flex">
          <div class="me-2">
            <div class="input-group">
              <span class="input-group-text border-end-0 bg-white"><i class="bi bi-circle"></i></span>
              <select class="form-select bg-white border-start-0 ps-0" style="max-width: 125px" id="canned_reply">
                <option value="">Canned reply</option>
                <option v-for="reply in canned_replies" v-bind:key="reply.id" :value="reply.id">
                  {{ reply.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="me-2">
            <div class="input-group">
              <span class="input-group-text border-end-0 bg-white"><i class="bi bi-circle"></i></span>
              <select v-model="ticket.details.status" class="form-select bg-white border-start-0 ps-0"
                style="max-width: 125px">
                <option disabled="">Status</option>
                <option value="submitted" :disabled="-1 === valid_statuses.indexOf('submitted')">
                  Submitted
                </option>
                <option value="accepted" :disabled="-1 === valid_statuses.indexOf('accepted')">
                  Accepted
                </option>
                <option value="working" :disabled="-1 === valid_statuses.indexOf('working')">
                  Working
                </option>
                <option value="resolved" :disabled="-1 === valid_statuses.indexOf('resolved')">
                  Resolved
                </option>
                <option value="closed" :disabled="-1 === valid_statuses.indexOf('closed')">
                  Closed
                </option>
                <option value="onhold" :disabled="-1 === valid_statuses.indexOf('onhold')">
                  Customer Hold
                </option>
              </select>
            </div>
          </div>
          <button type="button" class="btn btn-primary" @click="add_comment" :disabled="!valid_input">
            Comment
          </button>
        </div>
      </div>
    </div>
  </div>

  <ModalCloseTicket id="closeTicket" />
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";
import Utils from "../../helpers/utils.js";

import ModalCloseTicket from "../../components/modals/CloseTicket.vue";

export default {
  name: "AdminTicketDiscussion",
  components: { ModalCloseTicket },
  data() {
    return {
      ticket: {
        details: {
          id: this.hexDec(this.$route.params.ticket_id),
          status: "",
          original_status: "",
        },
        files: [],
        comments: [],
      },
      new_comment: "",
      canned_replies: [],
      valid_statuses: [],
      valid_input: false,
    };
  },
  mounted() {
    jQuery("#canned_reply").on("change", (ev) => {
      for (let i = 0; i < this.canned_replies.length; i++) {
        if (this.canned_replies[i].id == jQuery("#canned_reply").val()) {
          jQuery("#new_comment").val(this.canned_replies[i].body);

          this.new_comment = this.canned_replies[i].body;
        }
      }
    });

    jQuery("#fileAttachment").on("change", (ev) => {
      var app = this;

      var fileObj = ev.target.files[0],
        reader = new FileReader();

      reader.onloadend = function (blob) {
        var data = new FormData();
        data.append("image", fileObj);

        axios
          .post("/api/v1/tickets/" + app.ticket.details.id + "/file", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          })
          .then(
            (response) => {
              app.emitter.emit("popup_alert", {
                type: "success",
                text: "File uploaded",
              });

              app.fetch_ticket();
            },
            (error) => {
              console.log(error);
            }
          );
      };

      var blob = fileObj.slice(0, fileObj.size);
      reader.readAsBinaryString(blob);
    });

    this.fetch_ticket();
    this.fetch_canned_replies();
  },
  methods: {
    validate() {
      this.valid_input = this.new_comment.length > 3;
    },

    fetch_ticket() {
      axios
        .get("/api/v1/tickets/" + this.ticket.details.id, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.emitter.emit("ticket_details_loaded", response.data);

          this.ticket = response.data;

          this.ticket.details.original_status = this.ticket.details.status;

          switch (this.ticket.details.original_status) {
            case "submitted":
              this.valid_statuses = ["submitted", "accepted", "onhold"];
              break;
            case "accepted":
              this.valid_statuses = ["accepted", "working"];
              break;
            case "working":
              this.valid_statuses = ["working", "resolved", "onhold"];
              break;
            case "resolved":
              this.valid_statuses = ["resolved", "working", "closed"];
              break;
            case "closed":
              this.valid_statuses = ["closed"];
              break;
            case "onhold":
              this.valid_statuses = ["onhold", "submitted", "working"];
              break;
          }
        });
    },
    fetch_canned_replies() {
      axios
        .get("/api/v1/replies", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.canned_replies = response.data;
        });
    },
    add_comment() {
      let app = this;

      axios
        .post(
          "/api/v1/tickets/" + this.ticket.details.id + "/comments",
          {
            comment: this.new_comment,
            status: this.ticket.details.status,
          },
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Comment successfully added",
          });

          this.new_comment = "";

          app.fetch_ticket();
        });
    },
    download_file(path) {
      window.open("/ticket_files/" + path);
    },
    open_attach_files() {
      jQuery("#fileAttachment").click();
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    decHex(number) {
      return Utils.decHex(number);
    },
    hexDec(string) {
      return Utils.hexDec(string);
    },
    nlToBr(string) {
      return Utils.nlToBr(string, false);
    },
  },
  watch: {
    new_comment: function () {
      this.validate();
    },
  },
};
</script>