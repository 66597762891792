<template>
  <div class="container p-0">
    <div
      class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      "
    >
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item">
                <router-link to="/admin/categories" class="text-decoration-none"
                  >Categories</router-link
                >
              </li>
              <li class="breadcrumb-item active text-dark">
                {{ category.name }}
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-header bg-white">
          <i class="bi bi-menu-button-wide-fill me-2"></i>
          Edit Category
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <h5 class="mb-0">Category details</h5>
              <p class="text-muted">Category details and departments</p>
            </div>
            <div class="col-md-8">
              <div class="mb-3">
                <label class="form-label">Category Name</label>
                <input
                  v-model="category.name"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Departments</label>
                <label class="form-label float-end text-muted"
                  >Who will see the ticket</label
                >

                <ul class="list-group">
                  <li
                    v-for="department in category.departments"
                    v-bind:key="department.id"
                    class="list-group-item d-flex align-items-center"
                  >
                    <div
                      class="
                        flex-shrink-0
                        bg-white
                        border
                        bg-opacity-25
                        rounded-circle
                        d-flex
                      "
                      style="height: 45px; width: 45px"
                    >
                      <i
                        class="
                          bi bi-people-fill
                          fs-5
                          rounded-circle
                          align-self-center
                          mx-auto
                          text-dark
                        "
                      ></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <b class="text-dark small">{{ department.name }}</b>
                      <p class="small text-muted mb-0">N/A people</p>
                    </div>
                    <div class="flex-shrink-0 d-flex">
                      <div
                        class="
                          form-check form-control-lg form-switch
                          align-self-center
                        "
                      >
                        <input
                          class="form-check-input m-0"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          :checked="0 !== department.visible"
                          @click="toggle_visible(department.id)"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="float-start">
                <router-link
                  to="/admin/categories"
                  class="btn btn-outline-secondary"
                  >Close</router-link
                >
              </div>
              <div class="float-end">
                <button
                  type="button w-100"
                  class="btn btn-primary"
                  @click="save_category"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";

export default {
  name: "AdminCategory",
  components: {},
  data() {
    return {
      category: {
        id: this.$route.params.category_id,
        name: "",
        departments: [],
      },
    };
  },
  mounted() {
    this.fetch_category();
  },
  methods: {
    fetch_category() {
      axios
        .get(
          "/api/v1/categories/" + this.category.id,
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.category = response.data;
        });
    },
    toggle_visible(department) {
      for (let dep in this.category.departments) {
        if (this.category.departments[dep].id === department) {
          this.category.departments[dep].visible =
            1 - this.category.departments[dep].visible;
        }
      }
    },
    save_category() {
      var app = this;

      axios
        .patch(
          "/api/v1/categories/" + this.category.id,
          this.category,
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Category was successfully updated!",
          });

          this.fetch_category();
        });
    },
  },
  watch: {},
};
</script>