var moment = require("moment-timezone");

export default {
    localMachineTime(dateString) {
        let timezone = "europe_london";

        if (undefined !== process.appdata.user.timezone && null !== process.appdata.user.timezone) {
            timezone = process.appdata.user.timezone.replace(/_\-?\d+/, "");
        }

        let dateObj = moment(dateString + "+0000");
        dateObj.tz(timezone);

        return dateObj.format("YYYY-MM-DD HH:mm:ss");
    },

    localReadableTime(dateString) {
        let timezone = "europe_london";

        if (undefined !== process.appdata.user.timezone && null !== process.appdata.user.timezone) {
            timezone = process.appdata.user.timezone.replace(/_\-?\d+/, "");
        }

        let dateObj = moment(dateString + "+0000");
        dateObj.tz(timezone);

        return dateObj.format("HH:mm DD MMM YY");
    },

    timeAgo(dateString) {
        let dateObj = new Date(dateString + "+0000"),
            ts = dateObj.getTime(),
            now = new Date(),
            currentTs = now.getTime(),
            result = "";

        let diffSeconds = (currentTs - ts) / 1000;

        switch (true) {
            case diffSeconds < -86400 * 365:
                result = "in more than a year";
                break;
            case diffSeconds < -86400:
                result = "in " + Math.round(-diffSeconds / 86400) + " days";
                break;
            case diffSeconds < -3600:
                result = "in " + Math.round(-diffSeconds / 3600) + " hours";
                break;
            case diffSeconds < -60:
                result = "in " + Math.round(-diffSeconds / 60) + " minutes";
                break;
            case diffSeconds < 60:
                result = Math.round(diffSeconds) + " seconds ago";
                break;
            case diffSeconds < 3600:
                result = Math.round(diffSeconds / 60) + " minutes ago";
                break;
            case diffSeconds < 86400:
                result = Math.round(diffSeconds / 3600) + " hours ago";
                break;
            case diffSeconds < 86400 * 365:
                result = Math.round(diffSeconds / 86400) + " days ago";
                break;
            default:
                result = "more than a year ago";
        }

        return result;
    },

    elapsedTime(seconds) {
        let result;

        switch (true) {
            case !Number.isInteger(seconds):
                result = "N/A";
                break;
            case seconds < 0:
                result = "in zero time";
                break;
            case seconds < 60:
                result = Math.round(seconds) + " seconds";
                break;
            case seconds < 3600:
                result = Math.round(seconds / 60) + " minutes";
                break;
            case seconds < 86400:
                result = Math.round(seconds / 3600) + " hours";
                break;
            case seconds < 86400 * 365:
                result = Math.round(seconds / 86400) + " days";
                break;
        }

        return result;
    },

    capitalizeText(text, allwords) {
        if (undefined === text || null === text) {
            return null;
        }

        let result = "";
        if (allwords) {
            let words = text.split(" "), i;

            for (i = 0; i < words.length; i++) {
                words[i] = this.capitalizeText(words[i]);
            }
            result = words.join(" ");
        } else {
            result = text.charAt(0).toUpperCase() + text.slice(1);
        }

        return result;
    },

    readableTimezone(timezoneCode) {
        let timezones = {
            "africa_abidjan_0": "Africa/Abidjan (UTC+00:00)",
            "africa_accra_0": "Africa/Accra (UTC+00:00)",
            "africa_addis_ababa_10800": "Africa/Addis_Ababa (UTC+03:00)",
            "africa_algiers_3600": "Africa/Algiers (UTC+01:00)",
            "africa_asmara_10800": "Africa/Asmara (UTC+03:00)",
            "africa_bamako_0": "Africa/Bamako (UTC+00:00)",
            "africa_bangui_3600": "Africa/Bangui (UTC+01:00)",
            "africa_banjul_0": "Africa/Banjul (UTC+00:00)",
            "africa_bissau_0": "Africa/Bissau (UTC+00:00)",
            "africa_blantyre_7200": "Africa/Blantyre (UTC+02:00)",
            "africa_brazzaville_3600": "Africa/Brazzaville (UTC+01:00)",
            "africa_bujumbura_7200": "Africa/Bujumbura (UTC+02:00)",
            "africa_cairo_7200": "Africa/Cairo (UTC+02:00)",
            "africa_casablanca_3600": "Africa/Casablanca (UTC+01:00)",
            "africa_ceuta_7200": "Africa/Ceuta (UTC+02:00)",
            "africa_conakry_0": "Africa/Conakry (UTC+00:00)",
            "africa_dakar_0": "Africa/Dakar (UTC+00:00)",
            "africa_dar_es_salaam_10800": "Africa/Dar_es_Salaam (UTC+03:00)",
            "africa_djibouti_10800": "Africa/Djibouti (UTC+03:00)",
            "africa_douala_3600": "Africa/Douala (UTC+01:00)",
            "africa_el_aaiun_3600": "Africa/El_Aaiun (UTC+01:00)",
            "africa_freetown_0": "Africa/Freetown (UTC+00:00)",
            "africa_gaborone_7200": "Africa/Gaborone (UTC+02:00)",
            "africa_harare_7200": "Africa/Harare (UTC+02:00)",
            "africa_johannesburg_7200": "Africa/Johannesburg (UTC+02:00)",
            "africa_juba_7200": "Africa/Juba (UTC+02:00)",
            "africa_kampala_10800": "Africa/Kampala (UTC+03:00)",
            "africa_khartoum_7200": "Africa/Khartoum (UTC+02:00)",
            "africa_kigali_7200": "Africa/Kigali (UTC+02:00)",
            "africa_kinshasa_3600": "Africa/Kinshasa (UTC+01:00)",
            "africa_lagos_3600": "Africa/Lagos (UTC+01:00)",
            "africa_libreville_3600": "Africa/Libreville (UTC+01:00)",
            "africa_lome_0": "Africa/Lome (UTC+00:00)",
            "africa_luanda_3600": "Africa/Luanda (UTC+01:00)",
            "africa_lubumbashi_7200": "Africa/Lubumbashi (UTC+02:00)",
            "africa_lusaka_7200": "Africa/Lusaka (UTC+02:00)",
            "africa_malabo_3600": "Africa/Malabo (UTC+01:00)",
            "africa_maputo_7200": "Africa/Maputo (UTC+02:00)",
            "africa_maseru_7200": "Africa/Maseru (UTC+02:00)",
            "africa_mbabane_7200": "Africa/Mbabane (UTC+02:00)",
            "africa_mogadishu_10800": "Africa/Mogadishu (UTC+03:00)",
            "africa_monrovia_0": "Africa/Monrovia (UTC+00:00)",
            "africa_nairobi_10800": "Africa/Nairobi (UTC+03:00)",
            "africa_ndjamena_3600": "Africa/Ndjamena (UTC+01:00)",
            "africa_niamey_3600": "Africa/Niamey (UTC+01:00)",
            "africa_nouakchott_0": "Africa/Nouakchott (UTC+00:00)",
            "africa_ouagadougou_0": "Africa/Ouagadougou (UTC+00:00)",
            "africa_porto-novo_3600": "Africa/Porto-Novo (UTC+01:00)",
            "africa_sao_tome_0": "Africa/Sao_Tome (UTC+00:00)",
            "africa_tripoli_7200": "Africa/Tripoli (UTC+02:00)",
            "africa_tunis_3600": "Africa/Tunis (UTC+01:00)",
            "africa_windhoek_7200": "Africa/Windhoek (UTC+02:00)",
            "america_adak_-32400": "America/Adak (UTC-09:00)",
            "america_anchorage_-28800": "America/Anchorage (UTC-08:00)",
            "america_anguilla_-14400": "America/Anguilla (UTC-04:00)",
            "america_antigua_-14400": "America/Antigua (UTC-04:00)",
            "america_araguaina_-10800": "America/Araguaina (UTC-03:00)",
            "america_argentina_buenos_aires_-10800": "America/Argentina/Buenos_Aires (UTC-03:00)",
            "america_argentina_catamarca_-10800": "America/Argentina/Catamarca (UTC-03:00)",
            "america_argentina_cordoba_-10800": "America/Argentina/Cordoba (UTC-03:00)",
            "america_argentina_jujuy_-10800": "America/Argentina/Jujuy (UTC-03:00)",
            "america_argentina_la_rioja_-10800": "America/Argentina/La_Rioja (UTC-03:00)",
            "america_argentina_mendoza_-10800": "America/Argentina/Mendoza (UTC-03:00)",
            "america_argentina_rio_gallegos_-10800": "America/Argentina/Rio_Gallegos (UTC-03:00)",
            "america_argentina_salta_-10800": "America/Argentina/Salta (UTC-03:00)",
            "america_argentina_san_juan_-10800": "America/Argentina/San_Juan (UTC-03:00)",
            "america_argentina_san_luis_-10800": "America/Argentina/San_Luis (UTC-03:00)",
            "america_argentina_tucuman_-10800": "America/Argentina/Tucuman (UTC-03:00)",
            "america_argentina_ushuaia_-10800": "America/Argentina/Ushuaia (UTC-03:00)",
            "america_aruba_-14400": "America/Aruba (UTC-04:00)",
            "america_asuncion_-14400": "America/Asuncion (UTC-04:00)",
            "america_atikokan_-18000": "America/Atikokan (UTC-05:00)",
            "america_bahia_-10800": "America/Bahia (UTC-03:00)",
            "america_bahia_banderas_-18000": "America/Bahia_Banderas (UTC-05:00)",
            "america_barbados_-14400": "America/Barbados (UTC-04:00)",
            "america_belem_-10800": "America/Belem (UTC-03:00)",
            "america_belize_-21600": "America/Belize (UTC-06:00)",
            "america_blanc-sablon_-14400": "America/Blanc-Sablon (UTC-04:00)",
            "america_boa_vista_-14400": "America/Boa_Vista (UTC-04:00)",
            "america_bogota_-18000": "America/Bogota (UTC-05:00)",
            "america_boise_-21600": "America/Boise (UTC-06:00)",
            "america_cambridge_bay_-21600": "America/Cambridge_Bay (UTC-06:00)",
            "america_campo_grande_-14400": "America/Campo_Grande (UTC-04:00)",
            "america_cancun_-18000": "America/Cancun (UTC-05:00)",
            "america_caracas_-14400": "America/Caracas (UTC-04:00)",
            "america_cayenne_-10800": "America/Cayenne (UTC-03:00)",
            "america_cayman_-18000": "America/Cayman (UTC-05:00)",
            "america_chicago_-18000": "America/Chicago (UTC-05:00)",
            "america_chihuahua_-21600": "America/Chihuahua (UTC-06:00)",
            "america_costa_rica_-21600": "America/Costa_Rica (UTC-06:00)",
            "america_creston_-25200": "America/Creston (UTC-07:00)",
            "america_cuiaba_-14400": "America/Cuiaba (UTC-04:00)",
            "america_curacao_-14400": "America/Curacao (UTC-04:00)",
            "america_danmarkshavn_0": "America/Danmarkshavn (UTC+00:00)",
            "america_dawson_-25200": "America/Dawson (UTC-07:00)",
            "america_dawson_creek_-25200": "America/Dawson_Creek (UTC-07:00)",
            "america_denver_-21600": "America/Denver (UTC-06:00)",
            "america_detroit_-14400": "America/Detroit (UTC-04:00)",
            "america_dominica_-14400": "America/Dominica (UTC-04:00)",
            "america_edmonton_-21600": "America/Edmonton (UTC-06:00)",
            "america_eirunepe_-18000": "America/Eirunepe (UTC-05:00)",
            "america_el_salvador_-21600": "America/El_Salvador (UTC-06:00)",
            "america_fort_nelson_-25200": "America/Fort_Nelson (UTC-07:00)",
            "america_fortaleza_-10800": "America/Fortaleza (UTC-03:00)",
            "america_glace_bay_-10800": "America/Glace_Bay (UTC-03:00)",
            "america_goose_bay_-10800": "America/Goose_Bay (UTC-03:00)",
            "america_grand_turk_-14400": "America/Grand_Turk (UTC-04:00)",
            "america_grenada_-14400": "America/Grenada (UTC-04:00)",
            "america_guadeloupe_-14400": "America/Guadeloupe (UTC-04:00)",
            "america_guatemala_-21600": "America/Guatemala (UTC-06:00)",
            "america_guayaquil_-18000": "America/Guayaquil (UTC-05:00)",
            "america_guyana_-14400": "America/Guyana (UTC-04:00)",
            "america_halifax_-10800": "America/Halifax (UTC-03:00)",
            "america_havana_-14400": "America/Havana (UTC-04:00)",
            "america_hermosillo_-25200": "America/Hermosillo (UTC-07:00)",
            "america_indiana_indianapolis_-14400": "America/Indiana/Indianapolis (UTC-04:00)",
            "america_indiana_knox_-18000": "America/Indiana/Knox (UTC-05:00)",
            "america_indiana_marengo_-14400": "America/Indiana/Marengo (UTC-04:00)",
            "america_indiana_petersburg_-14400": "America/Indiana/Petersburg (UTC-04:00)",
            "america_indiana_tell_city_-18000": "America/Indiana/Tell_City (UTC-05:00)",
            "america_indiana_vevay_-14400": "America/Indiana/Vevay (UTC-04:00)",
            "america_indiana_vincennes_-14400": "America/Indiana/Vincennes (UTC-04:00)",
            "america_indiana_winamac_-14400": "America/Indiana/Winamac (UTC-04:00)",
            "america_inuvik_-21600": "America/Inuvik (UTC-06:00)",
            "america_iqaluit_-14400": "America/Iqaluit (UTC-04:00)",
            "america_jamaica_-18000": "America/Jamaica (UTC-05:00)",
            "america_juneau_-28800": "America/Juneau (UTC-08:00)",
            "america_kentucky_louisville_-14400": "America/Kentucky/Louisville (UTC-04:00)",
            "america_kentucky_monticello_-14400": "America/Kentucky/Monticello (UTC-04:00)",
            "america_kralendijk_-14400": "America/Kralendijk (UTC-04:00)",
            "america_la_paz_-14400": "America/La_Paz (UTC-04:00)",
            "america_lima_-18000": "America/Lima (UTC-05:00)",
            "america_los_angeles_-25200": "America/Los_Angeles (UTC-07:00)",
            "america_lower_princes_-14400": "America/Lower_Princes (UTC-04:00)",
            "america_maceio_-10800": "America/Maceio (UTC-03:00)",
            "america_managua_-21600": "America/Managua (UTC-06:00)",
            "america_manaus_-14400": "America/Manaus (UTC-04:00)",
            "america_marigot_-14400": "America/Marigot (UTC-04:00)",
            "america_martinique_-14400": "America/Martinique (UTC-04:00)",
            "america_matamoros_-18000": "America/Matamoros (UTC-05:00)",
            "america_mazatlan_-21600": "America/Mazatlan (UTC-06:00)",
            "america_menominee_-18000": "America/Menominee (UTC-05:00)",
            "america_merida_-18000": "America/Merida (UTC-05:00)",
            "america_metlakatla_-28800": "America/Metlakatla (UTC-08:00)",
            "america_mexico_city_-18000": "America/Mexico_City (UTC-05:00)",
            "america_miquelon_-7200": "America/Miquelon (UTC-02:00)",
            "america_moncton_-10800": "America/Moncton (UTC-03:00)",
            "america_monterrey_-18000": "America/Monterrey (UTC-05:00)",
            "america_montevideo_-10800": "America/Montevideo (UTC-03:00)",
            "america_montserrat_-14400": "America/Montserrat (UTC-04:00)",
            "america_nassau_-14400": "America/Nassau (UTC-04:00)",
            "america_new_york_-14400": "America/New_York (UTC-04:00)",
            "america_nipigon_-14400": "America/Nipigon (UTC-04:00)",
            "america_nome_-28800": "America/Nome (UTC-08:00)",
            "america_noronha_-7200": "America/Noronha (UTC-02:00)",
            "america_north_dakota_beulah_-18000": "America/North_Dakota/Beulah (UTC-05:00)",
            "america_north_dakota_center_-18000": "America/North_Dakota/Center (UTC-05:00)",
            "america_north_dakota_new_salem_-18000": "America/North_Dakota/New_Salem (UTC-05:00)",
            "america_nuuk_-7200": "America/Nuuk (UTC-02:00)",
            "america_ojinaga_-21600": "America/Ojinaga (UTC-06:00)",
            "america_panama_-18000": "America/Panama (UTC-05:00)",
            "america_pangnirtung_-14400": "America/Pangnirtung (UTC-04:00)",
            "america_paramaribo_-10800": "America/Paramaribo (UTC-03:00)",
            "america_phoenix_-25200": "America/Phoenix (UTC-07:00)",
            "america_port-au-prince_-14400": "America/Port-au-Prince (UTC-04:00)",
            "america_port_of_spain_-14400": "America/Port_of_Spain (UTC-04:00)",
            "america_porto_velho_-14400": "America/Porto_Velho (UTC-04:00)",
            "america_puerto_rico_-14400": "America/Puerto_Rico (UTC-04:00)",
            "america_punta_arenas_-10800": "America/Punta_Arenas (UTC-03:00)",
            "america_rainy_river_-18000": "America/Rainy_River (UTC-05:00)",
            "america_rankin_inlet_-18000": "America/Rankin_Inlet (UTC-05:00)",
            "america_recife_-10800": "America/Recife (UTC-03:00)",
            "america_regina_-21600": "America/Regina (UTC-06:00)",
            "america_resolute_-18000": "America/Resolute (UTC-05:00)",
            "america_rio_branco_-18000": "America/Rio_Branco (UTC-05:00)",
            "america_santarem_-10800": "America/Santarem (UTC-03:00)",
            "america_santiago_-14400": "America/Santiago (UTC-04:00)",
            "america_santo_domingo_-14400": "America/Santo_Domingo (UTC-04:00)",
            "america_sao_paulo_-10800": "America/Sao_Paulo (UTC-03:00)",
            "america_scoresbysund_0": "America/Scoresbysund (UTC+00:00)",
            "america_sitka_-28800": "America/Sitka (UTC-08:00)",
            "america_st_barthelemy_-14400": "America/St_Barthelemy (UTC-04:00)",
            "america_st_johns_-9000": "America/St_Johns (UTC-02:30)",
            "america_st_kitts_-14400": "America/St_Kitts (UTC-04:00)",
            "america_st_lucia_-14400": "America/St_Lucia (UTC-04:00)",
            "america_st_thomas_-14400": "America/St_Thomas (UTC-04:00)",
            "america_st_vincent_-14400": "America/St_Vincent (UTC-04:00)",
            "america_swift_current_-21600": "America/Swift_Current (UTC-06:00)",
            "america_tegucigalpa_-21600": "America/Tegucigalpa (UTC-06:00)",
            "america_thule_-10800": "America/Thule (UTC-03:00)",
            "america_thunder_bay_-14400": "America/Thunder_Bay (UTC-04:00)",
            "america_tijuana_-25200": "America/Tijuana (UTC-07:00)",
            "america_toronto_-14400": "America/Toronto (UTC-04:00)",
            "america_tortola_-14400": "America/Tortola (UTC-04:00)",
            "america_vancouver_-25200": "America/Vancouver (UTC-07:00)",
            "america_whitehorse_-25200": "America/Whitehorse (UTC-07:00)",
            "america_winnipeg_-18000": "America/Winnipeg (UTC-05:00)",
            "america_yakutat_-28800": "America/Yakutat (UTC-08:00)",
            "america_yellowknife_-21600": "America/Yellowknife (UTC-06:00)",
            "antarctica_casey_39600": "Antarctica/Casey (UTC+11:00)",
            "antarctica_davis_25200": "Antarctica/Davis (UTC+07:00)",
            "antarctica_dumontdurville_36000": "Antarctica/DumontDUrville (UTC+10:00)",
            "antarctica_macquarie_36000": "Antarctica/Macquarie (UTC+10:00)",
            "antarctica_mawson_18000": "Antarctica/Mawson (UTC+05:00)",
            "antarctica_mcmurdo_43200": "Antarctica/McMurdo (UTC+12:00)",
            "antarctica_palmer_-10800": "Antarctica/Palmer (UTC-03:00)",
            "antarctica_rothera_-10800": "Antarctica/Rothera (UTC-03:00)",
            "antarctica_syowa_10800": "Antarctica/Syowa (UTC+03:00)",
            "antarctica_troll_7200": "Antarctica/Troll (UTC+02:00)",
            "antarctica_vostok_21600": "Antarctica/Vostok (UTC+06:00)",
            "asia_aden_10800": "Asia/Aden (UTC+03:00)",
            "asia_almaty_21600": "Asia/Almaty (UTC+06:00)",
            "asia_amman_10800": "Asia/Amman (UTC+03:00)",
            "asia_anadyr_43200": "Asia/Anadyr (UTC+12:00)",
            "asia_aqtau_18000": "Asia/Aqtau (UTC+05:00)",
            "asia_aqtobe_18000": "Asia/Aqtobe (UTC+05:00)",
            "asia_ashgabat_18000": "Asia/Ashgabat (UTC+05:00)",
            "asia_atyrau_18000": "Asia/Atyrau (UTC+05:00)",
            "asia_baghdad_10800": "Asia/Baghdad (UTC+03:00)",
            "asia_bahrain_10800": "Asia/Bahrain (UTC+03:00)",
            "asia_baku_14400": "Asia/Baku (UTC+04:00)",
            "asia_bangkok_25200": "Asia/Bangkok (UTC+07:00)",
            "asia_barnaul_25200": "Asia/Barnaul (UTC+07:00)",
            "asia_beirut_10800": "Asia/Beirut (UTC+03:00)",
            "asia_bishkek_21600": "Asia/Bishkek (UTC+06:00)",
            "asia_brunei_28800": "Asia/Brunei (UTC+08:00)",
            "asia_chita_32400": "Asia/Chita (UTC+09:00)",
            "asia_choibalsan_28800": "Asia/Choibalsan (UTC+08:00)",
            "asia_colombo_19800": "Asia/Colombo (UTC+05:30)",
            "asia_damascus_10800": "Asia/Damascus (UTC+03:00)",
            "asia_dhaka_21600": "Asia/Dhaka (UTC+06:00)",
            "asia_dili_32400": "Asia/Dili (UTC+09:00)",
            "asia_dubai_14400": "Asia/Dubai (UTC+04:00)",
            "asia_dushanbe_18000": "Asia/Dushanbe (UTC+05:00)",
            "asia_famagusta_10800": "Asia/Famagusta (UTC+03:00)",
            "asia_gaza_10800": "Asia/Gaza (UTC+03:00)",
            "asia_hebron_10800": "Asia/Hebron (UTC+03:00)",
            "asia_ho_chi_minh_25200": "Asia/Ho_Chi_Minh (UTC+07:00)",
            "asia_hong_kong_28800": "Asia/Hong_Kong (UTC+08:00)",
            "asia_hovd_25200": "Asia/Hovd (UTC+07:00)",
            "asia_irkutsk_28800": "Asia/Irkutsk (UTC+08:00)",
            "asia_jakarta_25200": "Asia/Jakarta (UTC+07:00)",
            "asia_jayapura_32400": "Asia/Jayapura (UTC+09:00)",
            "asia_jerusalem_10800": "Asia/Jerusalem (UTC+03:00)",
            "asia_kabul_16200": "Asia/Kabul (UTC+04:30)",
            "asia_kamchatka_43200": "Asia/Kamchatka (UTC+12:00)",
            "asia_karachi_18000": "Asia/Karachi (UTC+05:00)",
            "asia_kathmandu_20700": "Asia/Kathmandu (UTC+05:45)",
            "asia_khandyga_32400": "Asia/Khandyga (UTC+09:00)",
            "asia_kolkata_19800": "Asia/Kolkata (UTC+05:30)",
            "asia_krasnoyarsk_25200": "Asia/Krasnoyarsk (UTC+07:00)",
            "asia_kuala_lumpur_28800": "Asia/Kuala_Lumpur (UTC+08:00)",
            "asia_kuching_28800": "Asia/Kuching (UTC+08:00)",
            "asia_kuwait_10800": "Asia/Kuwait (UTC+03:00)",
            "asia_macau_28800": "Asia/Macau (UTC+08:00)",
            "asia_magadan_39600": "Asia/Magadan (UTC+11:00)",
            "asia_makassar_28800": "Asia/Makassar (UTC+08:00)",
            "asia_manila_28800": "Asia/Manila (UTC+08:00)",
            "asia_muscat_14400": "Asia/Muscat (UTC+04:00)",
            "asia_nicosia_10800": "Asia/Nicosia (UTC+03:00)",
            "asia_novokuznetsk_25200": "Asia/Novokuznetsk (UTC+07:00)",
            "asia_novosibirsk_25200": "Asia/Novosibirsk (UTC+07:00)",
            "asia_omsk_21600": "Asia/Omsk (UTC+06:00)",
            "asia_oral_18000": "Asia/Oral (UTC+05:00)",
            "asia_phnom_penh_25200": "Asia/Phnom_Penh (UTC+07:00)",
            "asia_pontianak_25200": "Asia/Pontianak (UTC+07:00)",
            "asia_pyongyang_32400": "Asia/Pyongyang (UTC+09:00)",
            "asia_qatar_10800": "Asia/Qatar (UTC+03:00)",
            "asia_qostanay_21600": "Asia/Qostanay (UTC+06:00)",
            "asia_qyzylorda_18000": "Asia/Qyzylorda (UTC+05:00)",
            "asia_riyadh_10800": "Asia/Riyadh (UTC+03:00)",
            "asia_sakhalin_39600": "Asia/Sakhalin (UTC+11:00)",
            "asia_samarkand_18000": "Asia/Samarkand (UTC+05:00)",
            "asia_seoul_32400": "Asia/Seoul (UTC+09:00)",
            "asia_shanghai_28800": "Asia/Shanghai (UTC+08:00)",
            "asia_singapore_28800": "Asia/Singapore (UTC+08:00)",
            "asia_srednekolymsk_39600": "Asia/Srednekolymsk (UTC+11:00)",
            "asia_taipei_28800": "Asia/Taipei (UTC+08:00)",
            "asia_tashkent_18000": "Asia/Tashkent (UTC+05:00)",
            "asia_tbilisi_14400": "Asia/Tbilisi (UTC+04:00)",
            "asia_tehran_16200": "Asia/Tehran (UTC+04:30)",
            "asia_thimphu_21600": "Asia/Thimphu (UTC+06:00)",
            "asia_tokyo_32400": "Asia/Tokyo (UTC+09:00)",
            "asia_tomsk_25200": "Asia/Tomsk (UTC+07:00)",
            "asia_ulaanbaatar_28800": "Asia/Ulaanbaatar (UTC+08:00)",
            "asia_urumqi_21600": "Asia/Urumqi (UTC+06:00)",
            "asia_ust-nera_36000": "Asia/Ust-Nera (UTC+10:00)",
            "asia_vientiane_25200": "Asia/Vientiane (UTC+07:00)",
            "asia_vladivostok_36000": "Asia/Vladivostok (UTC+10:00)",
            "asia_yakutsk_32400": "Asia/Yakutsk (UTC+09:00)",
            "asia_yangon_23400": "Asia/Yangon (UTC+06:30)",
            "asia_yekaterinburg_18000": "Asia/Yekaterinburg (UTC+05:00)",
            "asia_yerevan_14400": "Asia/Yerevan (UTC+04:00)",
            "atlantic_azores_0": "Atlantic/Azores (UTC+00:00)",
            "atlantic_bermuda_-10800": "Atlantic/Bermuda (UTC-03:00)",
            "atlantic_canary_3600": "Atlantic/Canary (UTC+01:00)",
            "atlantic_cape_verde_-3600": "Atlantic/Cape_Verde (UTC-01:00)",
            "atlantic_faroe_3600": "Atlantic/Faroe (UTC+01:00)",
            "atlantic_madeira_3600": "Atlantic/Madeira (UTC+01:00)",
            "atlantic_reykjavik_0": "Atlantic/Reykjavik (UTC+00:00)",
            "atlantic_south_georgia_-7200": "Atlantic/South_Georgia (UTC-02:00)",
            "atlantic_st_helena_0": "Atlantic/St_Helena (UTC+00:00)",
            "atlantic_stanley_-10800": "Atlantic/Stanley (UTC-03:00)",
            "australia_adelaide_34200": "Australia/Adelaide (UTC+09:30)",
            "australia_brisbane_36000": "Australia/Brisbane (UTC+10:00)",
            "australia_broken_hill_34200": "Australia/Broken_Hill (UTC+09:30)",
            "australia_darwin_34200": "Australia/Darwin (UTC+09:30)",
            "australia_eucla_31500": "Australia/Eucla (UTC+08:45)",
            "australia_hobart_36000": "Australia/Hobart (UTC+10:00)",
            "australia_lindeman_36000": "Australia/Lindeman (UTC+10:00)",
            "australia_lord_howe_37800": "Australia/Lord_Howe (UTC+10:30)",
            "australia_melbourne_36000": "Australia/Melbourne (UTC+10:00)",
            "australia_perth_28800": "Australia/Perth (UTC+08:00)",
            "australia_sydney_36000": "Australia/Sydney (UTC+10:00)",
            "europe_amsterdam_7200": "Europe/Amsterdam (UTC+02:00)",
            "europe_andorra_7200": "Europe/Andorra (UTC+02:00)",
            "europe_astrakhan_14400": "Europe/Astrakhan (UTC+04:00)",
            "europe_athens_10800": "Europe/Athens (UTC+03:00)",
            "europe_belgrade_7200": "Europe/Belgrade (UTC+02:00)",
            "europe_berlin_7200": "Europe/Berlin (UTC+02:00)",
            "europe_bratislava_7200": "Europe/Bratislava (UTC+02:00)",
            "europe_brussels_7200": "Europe/Brussels (UTC+02:00)",
            "europe_bucharest_10800": "Europe/Bucharest (UTC+03:00)",
            "europe_budapest_7200": "Europe/Budapest (UTC+02:00)",
            "europe_busingen_7200": "Europe/Busingen (UTC+02:00)",
            "europe_chisinau_10800": "Europe/Chisinau (UTC+03:00)",
            "europe_copenhagen_7200": "Europe/Copenhagen (UTC+02:00)",
            "europe_dublin_3600": "Europe/Dublin (UTC+01:00)",
            "europe_gibraltar_7200": "Europe/Gibraltar (UTC+02:00)",
            "europe_guernsey_3600": "Europe/Guernsey (UTC+01:00)",
            "europe_helsinki_10800": "Europe/Helsinki (UTC+03:00)",
            "europe_isle_of_man_3600": "Europe/Isle_of_Man (UTC+01:00)",
            "europe_istanbul_10800": "Europe/Istanbul (UTC+03:00)",
            "europe_jersey_3600": "Europe/Jersey (UTC+01:00)",
            "europe_kaliningrad_7200": "Europe/Kaliningrad (UTC+02:00)",
            "europe_kiev_10800": "Europe/Kiev (UTC+03:00)",
            "europe_kirov_10800": "Europe/Kirov (UTC+03:00)",
            "europe_lisbon_3600": "Europe/Lisbon (UTC+01:00)",
            "europe_ljubljana_7200": "Europe/Ljubljana (UTC+02:00)",
            "europe_london_3600": "Europe/London (UTC+01:00)",
            "europe_luxembourg_7200": "Europe/Luxembourg (UTC+02:00)",
            "europe_madrid_7200": "Europe/Madrid (UTC+02:00)",
            "europe_malta_7200": "Europe/Malta (UTC+02:00)",
            "europe_mariehamn_10800": "Europe/Mariehamn (UTC+03:00)",
            "europe_minsk_10800": "Europe/Minsk (UTC+03:00)",
            "europe_monaco_7200": "Europe/Monaco (UTC+02:00)",
            "europe_moscow_10800": "Europe/Moscow (UTC+03:00)",
            "europe_oslo_7200": "Europe/Oslo (UTC+02:00)",
            "europe_paris_7200": "Europe/Paris (UTC+02:00)",
            "europe_podgorica_7200": "Europe/Podgorica (UTC+02:00)",
            "europe_prague_7200": "Europe/Prague (UTC+02:00)",
            "europe_riga_10800": "Europe/Riga (UTC+03:00)",
            "europe_rome_7200": "Europe/Rome (UTC+02:00)",
            "europe_samara_14400": "Europe/Samara (UTC+04:00)",
            "europe_san_marino_7200": "Europe/San_Marino (UTC+02:00)",
            "europe_sarajevo_7200": "Europe/Sarajevo (UTC+02:00)",
            "europe_saratov_14400": "Europe/Saratov (UTC+04:00)",
            "europe_simferopol_10800": "Europe/Simferopol (UTC+03:00)",
            "europe_skopje_7200": "Europe/Skopje (UTC+02:00)",
            "europe_sofia_10800": "Europe/Sofia (UTC+03:00)",
            "europe_stockholm_7200": "Europe/Stockholm (UTC+02:00)",
            "europe_tallinn_10800": "Europe/Tallinn (UTC+03:00)",
            "europe_tirane_7200": "Europe/Tirane (UTC+02:00)",
            "europe_ulyanovsk_14400": "Europe/Ulyanovsk (UTC+04:00)",
            "europe_uzhgorod_10800": "Europe/Uzhgorod (UTC+03:00)",
            "europe_vaduz_7200": "Europe/Vaduz (UTC+02:00)",
            "europe_vatican_7200": "Europe/Vatican (UTC+02:00)",
            "europe_vienna_7200": "Europe/Vienna (UTC+02:00)",
            "europe_vilnius_10800": "Europe/Vilnius (UTC+03:00)",
            "europe_volgograd_10800": "Europe/Volgograd (UTC+03:00)",
            "europe_warsaw_7200": "Europe/Warsaw (UTC+02:00)",
            "europe_zagreb_7200": "Europe/Zagreb (UTC+02:00)",
            "europe_zaporozhye_10800": "Europe/Zaporozhye (UTC+03:00)",
            "europe_zurich_7200": "Europe/Zurich (UTC+02:00)",
            "indian_antananarivo_10800": "Indian/Antananarivo (UTC+03:00)",
            "indian_chagos_21600": "Indian/Chagos (UTC+06:00)",
            "indian_christmas_25200": "Indian/Christmas (UTC+07:00)",
            "indian_cocos_23400": "Indian/Cocos (UTC+06:30)",
            "indian_comoro_10800": "Indian/Comoro (UTC+03:00)",
            "indian_kerguelen_18000": "Indian/Kerguelen (UTC+05:00)",
            "indian_mahe_14400": "Indian/Mahe (UTC+04:00)",
            "indian_maldives_18000": "Indian/Maldives (UTC+05:00)",
            "indian_mauritius_14400": "Indian/Mauritius (UTC+04:00)",
            "indian_mayotte_10800": "Indian/Mayotte (UTC+03:00)",
            "indian_reunion_14400": "Indian/Reunion (UTC+04:00)",
            "pacific_apia_46800": "Pacific/Apia (UTC+13:00)",
            "pacific_auckland_43200": "Pacific/Auckland (UTC+12:00)",
            "pacific_bougainville_39600": "Pacific/Bougainville (UTC+11:00)",
            "pacific_chatham_45900": "Pacific/Chatham (UTC+12:45)",
            "pacific_chuuk_36000": "Pacific/Chuuk (UTC+10:00)",
            "pacific_easter_-21600": "Pacific/Easter (UTC-06:00)",
            "pacific_efate_39600": "Pacific/Efate (UTC+11:00)",
            "pacific_enderbury_46800": "Pacific/Enderbury (UTC+13:00)",
            "pacific_fakaofo_46800": "Pacific/Fakaofo (UTC+13:00)",
            "pacific_fiji_43200": "Pacific/Fiji (UTC+12:00)",
            "pacific_funafuti_43200": "Pacific/Funafuti (UTC+12:00)",
            "pacific_galapagos_-21600": "Pacific/Galapagos (UTC-06:00)",
            "pacific_gambier_-32400": "Pacific/Gambier (UTC-09:00)",
            "pacific_guadalcanal_39600": "Pacific/Guadalcanal (UTC+11:00)",
            "pacific_guam_36000": "Pacific/Guam (UTC+10:00)",
            "pacific_honolulu_-36000": "Pacific/Honolulu (UTC-10:00)",
            "pacific_kiritimati_50400": "Pacific/Kiritimati (UTC+14:00)",
            "pacific_kosrae_39600": "Pacific/Kosrae (UTC+11:00)",
            "pacific_kwajalein_43200": "Pacific/Kwajalein (UTC+12:00)",
            "pacific_majuro_43200": "Pacific/Majuro (UTC+12:00)",
            "pacific_marquesas_-34200": "Pacific/Marquesas (UTC-09:30)",
            "pacific_midway_-39600": "Pacific/Midway (UTC-11:00)",
            "pacific_nauru_43200": "Pacific/Nauru (UTC+12:00)",
            "pacific_niue_-39600": "Pacific/Niue (UTC-11:00)",
            "pacific_norfolk_39600": "Pacific/Norfolk (UTC+11:00)",
            "pacific_noumea_39600": "Pacific/Noumea (UTC+11:00)",
            "pacific_pago_pago_-39600": "Pacific/Pago_Pago (UTC-11:00)",
            "pacific_palau_32400": "Pacific/Palau (UTC+09:00)",
            "pacific_pitcairn_-28800": "Pacific/Pitcairn (UTC-08:00)",
            "pacific_pohnpei_39600": "Pacific/Pohnpei (UTC+11:00)",
            "pacific_port_moresby_36000": "Pacific/Port_Moresby (UTC+10:00)",
            "pacific_rarotonga_-36000": "Pacific/Rarotonga (UTC-10:00)",
            "pacific_saipan_36000": "Pacific/Saipan (UTC+10:00)",
            "pacific_tahiti_-36000": "Pacific/Tahiti (UTC-10:00)",
            "pacific_tarawa_43200": "Pacific/Tarawa (UTC+12:00)",
            "pacific_tongatapu_46800": "Pacific/Tongatapu (UTC+13:00)",
            "pacific_wake_43200": "Pacific/Wake (UTC+12:00)",
            "pacific_wallis_43200": "Pacific/Wallis (UTC+12:00)"
        }

        return timezones[timezoneCode];
    },

    decHex(number) {
        try {
            return number.toString(16);
        } catch (err) {
            return "";
        }
    },

    hexDec(string) {
        try {
            return parseInt(string, 16);
        } catch (err) {
            return 0;
        }
    },

    nlToBr(str, is_xhtml) {
        // http://kevin.vanzonneveld.net
        // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   improved by: Philip Peterson
        // +   improved by: Onno Marsman
        // +   improved by: Atli Þór
        // +   bugfixed by: Onno Marsman
        // +      input by: Brett Zamir (http://brett-zamir.me)
        // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   improved by: Brett Zamir (http://brett-zamir.me)
        // +   improved by: Maximusya
        // *     example 1: nl2br('Kevin\nvan\nZonneveld');
        // *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
        // *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
        // *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
        // *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
        // *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
}