<template>
  <div class="container p-0">
    <div
      class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      "
    >
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item">
                <a href="/admin/replies" class="text-decoration-none"
                  >Canned replies</a
                >
              </li>
              <li class="breadcrumb-item active text-dark">
                {{ "" === reply.name ? "New Reply" : reply.name }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card mb-2">
        <div class="card-header bg-white">
          <i class="bi bi-people-fill me-2"></i> New Canned reply
        </div>
        <div class="card-body">
          <div class="row">
            <div
              class="col-md-4 d-flex align-items-start flex-column bd-highlight"
            >
              <div class="mb-auto">
                <h5 class="mb-0">Canned reply details</h5>
                <p class="text-muted">Canned reply details and settings</p>
              </div>
            </div>
            <div class="col-md-8">
              <div class="mb-3">
                <label class="form-label">Name</label
                ><input v-model="reply.name" type="text" class="form-control" />
              </div>
              <div class="mb-3">
                <label class="form-label">Shared</label>
                <div class="form-control-lg form-check form-switch py-0">
                  <input
                    v-model="reply.shared"
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                  /><label
                    class="form-check-label small text-muted"
                    for="flexSwitchCheckDefault"
                    >The canned reply is
                    {{ reply.shared ? "" : "not" }} shared</label
                  >
                </div>
                <div class="form-text">
                  A shared saved answer, allows other users to use it.
                </div>
              </div>
              <div>
                <label class="form-label">Body</label
                ><textarea
                  v-model="reply.body"
                  class="form-control"
                  rows="15"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer bg-white border-top-0 pt-0">
          <div class="row">
            <div class="col-md-8 ms-auto">
              <div class="float-start">
                <router-link
                  to="/admin/replies"
                  class="btn btn-outline-secondary"
                  >Close</router-link
                >
              </div>
              <div class="float-end">
                <button
                  type="button w-100"
                  class="btn btn-primary"
                  @click="add_reply"
                >
                  Add reply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";

export default {
  name: "AdminNewReply",
  components: {},
  data() {
    return {
      reply: {
        name: "",
        body: "",
        shared: false,
      },
    };
  },
  mounted() {},
  methods: {
    add_reply() {
      var app = this,
        data = this.reply;

      data.shared = data.shared ? 1 : 0;

      axios
        .post("/api/v1/replies", data, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then(function (response) {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Canned reply was successfully created!",
          });

          setTimeout(() => {
            app.$router.push("/admin/replies");
          }, 2500);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  watch: {},
};
</script>