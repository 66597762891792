<template>
  <ul class="list-group mb-2">
    <li class="list-group-item rounded-0 border-0 border-bottom small">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li
              class="breadcrumb-item active"
              v-for="(item, index) in breaditems"
              v-bind:key="index"
            >
              <span v-if="null == item.link">{{ item.title }}</span>
              <router-link
                v-else
                :to="item.link"
                class="text-decoration-none"
                >{{ item.title }}</router-link
              >
            </li>
          </ol>
        </nav>
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  name: "Breadcrumbs",
  props: {
    breaditems: Array,
  }
};
</script>