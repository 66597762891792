<template>
  <div class="card mb-2">
    <div class="card-header bg-white">
      <i class="bi bi-file-text me-2"></i>
      Information
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-hash me-3"></i>Ticket ID:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="align-self-center small font-monospace">{{
                decHex(ticket.details.id)
            }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-question-circle me-3"></i>Subject:
          </div>
          <div class="w-75 py-2 px-3">
            {{ ticket.details.subject }}

            <button type="button" class="btn btn-link float-end text-decoration-none p-0" data-bs-toggle="modal"
              data-bs-target="#ticketSubject">
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-building me-3"></i>Organisation:
          </div>
          <div v-if="null !== ticket.details.organisation_id" class="w-75 py-2 px-3">
            <a href="" class="text-decoration-none">{{
                ticket.details.organisation_name
            }}</a>

            <button type="button" class="btn btn-link float-end text-decoration-none p-0" data-bs-toggle="modal"
              data-bs-target="#ticketInformation">
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-patch-check me-3"></i>Organisation Type:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="badge bg-warning bg-opacity-25 text-warning">{{
                ticket.organisation_type
            }}</span>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-pin me-3"></i>Site:
          </div>
          <div class="w-75 py-2 px-3">
            {{ ticket.details.site_name }}

            <button type="button" class="btn btn-link float-end text-decoration-none p-0" data-bs-toggle="modal"
              data-bs-target="#ticketInformation">
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-window-sidebar me-3"></i>Service:
          </div>
          <div class="w-75 py-2 px-3">
            {{ ticket.details.service_name }}

            <button type="button" class="btn btn-link float-end text-decoration-none p-0" data-bs-toggle="modal"
              data-bs-target="#ticketInformation">
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-menu-button-wide me-3"></i>Category:
          </div>
          <div class="w-75 py-2 px-3">
            {{ ticket.details.category_name }}

            <button type="button" class="btn btn-link float-end text-decoration-none p-0" data-bs-toggle="modal"
              data-bs-target="#ticketCategory">
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-card-text me-3"></i>Assignee:
          </div>
          <div class="w-75 py-2 px-3">
            <a :href="umsURL + '/admin/users/' + ticket.details.assignee" target="_blank"
              class="text-decoration-none">{{ ticket.details.assignee_name }}</a>
            <button v-if="isAdmin" type="button" class="btn btn-link p-0 float-end text-decoration-none"
              data-bs-toggle="modal" data-bs-target="#ticketAssignee">
              Update
            </button>
            <button v-else-if="null === ticket.details.assignee || '' === ticket.details.assignee" type="button"
              class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#ticketSelfAssign">
              Assign to Yourself
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-circle me-3"></i>Created By:
          </div>
          <div class="w-75 py-2 px-3">
            <a :href="umsURL + '/admin/users/' + ticket.details.creator_id" target="_blank"
              class="text-decoration-none">{{ ticket.details.creator_name }}</a>
            <a :href="
              umsURL + '/users/' + ticket.details.creator_organisation_id
            " target="_blank" class="text-decoration-none float-end">{{ ticket.details.creator_organisation_name }}</a>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-calendar-day me-3"></i>Created At:
          </div>
          <div class="w-75 py-2 px-3">
            {{ localMachineTime(ticket.details.created_at) }}
            <span class="float-end">{{
                timeAgo(ticket.details.created_at)
            }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-calendar-event me-3"></i>Closed At:
          </div>
          <div v-if="'closed' === ticket.details.status" class="w-75 py-2 px-3">
            {{ localMachineTime(ticket.details.closed_at) }}
            <span class="float-end">{{
                timeAgo(ticket.details.closed_at)
            }}</span>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-clock me-3"></i>Response Time:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="badge bg-primary bg-opacity-25 text-primary">{{
                elapsedTime(ticket.details.response_time)
            }}</span>
          </div>
        </li>
        <li v-if="'closed' === ticket.details.status" class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-clock me-3"></i>Resolve Time:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="badge bg-primary bg-opacity-25 text-primary">{{
                elapsedTime(ticket.details.resolve_time)
            }}</span>
          </div>
        </li>
        <li v-else class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-clock me-3"></i>Elapsed Time:
          </div>
          <div class="w-75 py-2 px-3">
            <span class="badge bg-primary bg-opacity-25 text-primary">{{
                elapsedTime(ticket.details.elapsed_time)
            }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="card mb-2">
    <div class="card-header bg-white">
      <i class="bi bi-cpu me-2"></i>
      Devices
    </div>
    <ul class="list-group list-group-flush">
      <li v-for="device in ticket.devices" v-bind:key="device.id" class="list-group-item d-flex align-items-center">
        <div class="flex-shrink-0">
          <i v-if="'sensor' == device.type" class="bi bi-thermometer-half fs-4"></i>
          <i v-if="'module' == device.type" class="bi bi-cpu fs-4"></i>
          <i v-if="'gateway' == device.type" class="bi bi-wifi fs-4"></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <b class="text-dark font-monospace small">{{ device.eui }}</b>
          <p class="small text-muted mb-0">
            {{ device.name + " (" + device.manufactured_year + ")" }}
          </p>
        </div>
        <div class="flex-grow-0">
          <a :href="'https://iotcore.me/' + device.type + 's/' + device.eui" target="_blank"
            class="float-end text-decoration-none">View on IoT Core</a>
        </div>
      </li>
    </ul>
  </div>

  <ModalTicketSubject id="ticketSubject" />
  <ModalTicketInformation id="ticketInformation" />
  <ModalTicketCategory id="ticketCategory" />
  <ModalTicketAssignee id="ticketAssignee" />
  <ModalTicketSelfAssign id="ticketSelfAssign" />
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";
import Utils from "../../helpers/utils.js";

import ModalTicketSubject from "../../components/modals/TicketSubject.vue";
import ModalTicketInformation from "../../components/modals/TicketInformation.vue";
import ModalTicketCategory from "../../components/modals/TicketCategory.vue";
import ModalTicketAssignee from "../../components/modals/TicketAssignee.vue";
import ModalTicketSelfAssign from "../../components/modals/TicketSelfAssign.vue";

export default {
  name: "AdminTicketInformation",
  components: {
    ModalTicketSubject,
    ModalTicketInformation,
    ModalTicketCategory,
    ModalTicketAssignee,
    ModalTicketSelfAssign,
  },
  computed: {
    isAdmin: () => {
      return process.appdata.user && "admin" === process.appdata.user.type;
    },
    umsURL() {
      return process.env.MIX_RT_ACCOUNT_URL;
    },
  },
  data() {
    return {
      ticket: {
        details: {
          id: this.hexDec(this.$route.params.ticket_id),
        },
        devices: [],
      },
    };
  },
  mounted() {
    this.emitter.on("ticket_details_updated", (data) => {
      this.fetch_ticket();
    });

    this.fetch_ticket();
  },
  methods: {
    fetch_ticket() {
      axios
        .get("/api/v1/tickets/" + this.ticket.details.id, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.ticket = response.data;

          this.emitter.emit("ticket_details_loaded", this.ticket);
        });
    },
    elapsedTime(dateString) {
      return Utils.elapsedTime(dateString);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    decHex(number) {
      return Utils.decHex(number);
    },
    hexDec(string) {
      return Utils.hexDec(string);
    },
  },
  watch: {},
};
</script>