<template>
  <div class="container p-0">
    <div class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      ">
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item">
                <router-link to="/admin/tickets" class="text-decoration-none">Tickets</router-link>
              </li>
              <li class="breadcrumb-item active text-dark">
                #{{ decHex(ticket.id) }}
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="row g-2">
        <div class="col-md-3 mt-2">
          <div class="card mb-2">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <b class="text-dark font-monospace">Ticket {{ decHex(ticket.id) }}</b>
                  <p class="small text-muted mb-0">
                    <span class="badge bg-primary bg-opacity-25 text-primary" data-bs-toggle="tooltip"
                      data-bs-placement="top" title="" data-bs-original-title="Resolve Time">{{
                          elapsedTime(ticket.resolve_time)
                      }}</span>
                  </p>
                </div>
                <div class="flex-grow-1">
                  <div class="float-end align-self-center mx-auto small">
                    <span class="badge bg-info bg-opacity-25 text-info" data-bs-toggle="tooltip"
                      data-bs-placement="top" title="" data-bs-original-title="Status">{{
                        capitalizeText(ticket.status)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group list-group-flush">
              <router-link :to="'/admin/tickets/' + decHex(ticket.id)" class="
                  border-white
                  list-group-item list-group-item-action
                  d-flex
                  gap-2
                  align-items-center
                " :class="
                  'discussion' === active_menu
                    ? 'text-primary bg-primary bg-opacity-25 '
                    : ''
                " @click="setActiveMenu('discussion')">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="bi bi-chat-right-text"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">Discussion</div>
                </div>
              </router-link>
              <router-link :to="'/admin/tickets/' + decHex(ticket.id) + '/statushistory'" class="
                  list-group-item list-group-item-action
                  d-flex
                  gap-2
                  align-items-center
                " :class="'statushistory' === active_menu ? 'active ' : ''" @click="setActiveMenu('statushistory')">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="bi bi-clock"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">Status History</div>
                </div>
              </router-link>
              <router-link :to="'/admin/tickets/' + decHex(ticket.id) + '/information'" class="
                  list-group-item list-group-item-action
                  d-flex
                  gap-2
                  align-items-center
                " :class="
                  'information' === active_menu
                    ? 'text-primary bg-primary bg-opacity-25 '
                    : ''
                " @click="setActiveMenu('information')">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="bi bi-file-text"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">Information</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-9 mt-2">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";
import Utils from "../../helpers/utils.js";

export default {
  name: "AdminTicket",
  components: {},
  data() {
    return {
      active_menu: "discussion",
      ticket: {
        id: this.hexDec(this.$route.params.ticket_id),
      },
    };
  },
  created() {
    this.active_menu = this.$route.meta.submenu;
  },
  mounted() {
    this.fetch_ticket_stats();

    setTimeout(() => {
      jQuery("[data-bs-toggle='tooltip']").each(function () {
        jQuery(this).tooltip();
      });
    }, 1000);
  },
  methods: {
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
    fetch_ticket_stats() {
      axios
        .get("/api/v1/tickets/" + this.ticket.id + "/stats", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.ticket = response.data;
        });
    },
    decHex(number) {
      return Utils.decHex(number);
    },
    hexDec(string) {
      return Utils.hexDec(string);
    },
    elapsedTime(seconds) {
      return Utils.elapsedTime(seconds);
    },
    capitalizeText(string) {
      return Utils.capitalizeText(string, true);
    },
  },
};
</script>