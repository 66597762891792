<template>
  <Breadcrumbs :breaditems="[{ title: 'My Tickets' }]" />

  <div class="container-xl">
    <div class="d-flex mb-2">
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"
            ><i class="bi bi-circle"></i
          ></span>
          <select
            v-model="filter.status"
            class="form-select bg-white border-start-0 ps-0"
            style="max-width: 125px"
          >
            <option disabled="">Status</option>
            <option value="">All Tickets</option>
            <option value="submitted">Submitted</option>
            <option value="accepted">Accepted</option>
            <option value="working">Working</option>
            <option value="resolved">Resolved</option>
            <option value="closed">Closed</option>
            <option value="onhold">On Hold</option>
          </select>
        </div>
      </div>
      <div class="me-2">
        <div class="input-group">
          <span class="input-group-text border-end-0 bg-white" id="basic-addon1"
            ><i class="bi bi-window-sidebar"></i
          ></span>
          <select
            v-model="filter.service"
            class="form-select bg-white border-start-0 ps-0"
            style="max-width: 125px"
          >
            <option disabled="">Service</option>
            <option value="">All Services</option>
            <option value="n/a">Other</option>
            <option
              v-for="service in services"
              v-bind:key="service.id"
              :value="service.id"
            >
              {{ service.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="ms-auto">
        <div class="input-group">
          <span class="input-group-text bg-white border-end-0"
            ><i class="bi bi-search"></i
          ></span>
          <input
            type="text"
            class="form-control border-start-0"
            v-model="filter.name"
            v-on:keyup.enter="fetch_tickets()"
            placeholder="Search..."
          />
        </div>
      </div>
      <div class="ms-2">
        <router-link class="btn btn-dark" to="/tickets/new"
          >New Ticket</router-link
        >
      </div>
    </div>

    <div class="card mb-2">
      <div class="list-group list-group-flush">
        <div v-if="!tickets || 0 === tickets.length" class="card-body">
          <div class="text-center">
            <img src="/images/empty-tickets.svg" class="img-fluid py-5" />
          </div>
        </div>

        <div v-else>
          <div class="list-group-item bg-light">
            <div class="row">
              <div
                class="col-md-7 border-end d-flex justify-content-between"
                @click="sortBy('id')"
              >
                <span>Subject / Ticket ID</span>
                <i
                  class="bi bi-arrow-down-up"
                  :class="'id' === sortby ? 'text-primary' : ''"
                ></i>
              </div>
              <div class="col-md-5">
                <div class="row">
                  <div
                    class="col-md-4 border-end d-flex justify-content-between"
                    @click="sortBy('id')"
                  >
                    <span>Elapsed Time</span>
                    <i class="bi bi-arrow-down-up"></i>
                  </div>
                  <div
                    class="col-md-3 border-end d-flex justify-content-between"
                    @click="sortBy('status')"
                  >
                    <span>Status</span>
                    <i
                      class="bi bi-arrow-down-up"
                      :class="'status' === sortby ? 'text-primary' : ''"
                    ></i>
                  </div>
                  <div
                    class="col-md-5 d-flex justify-content-between"
                    @click="sortBy('updated_at')"
                  >
                    <span>Updated</span>
                    <i
                      class="bi bi-arrow-down-up"
                      :class="'updated_at' === sortby ? 'text-primary' : ''"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-link
            v-for="ticket in tickets"
            v-bind:key="ticket.id"
            :to="'/tickets/' + decHex(ticket.id)"
            class="list-group-item list-group-item-action"
          >
            <div class="row">
              <div class="col-md-7 border-end">
                <div class="d-flex align-items-center">
                  <div
                    class="
                      flex-shrink-0
                      bg-primary bg-opacity-25
                      rounded-circle
                      d-flex
                    "
                    style="height: 45px; width: 45px"
                  >
                    <i
                      class="
                        bi bi-life-preserver
                        fs-5
                        rounded-circle
                        align-self-center
                        mx-auto
                        text-primary
                      "
                    ></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <b class="text-dark font-monospace small">{{
                      ticket.subject
                    }}</b>
                    <p class="small text-muted mb-0">{{ decHex(ticket.id) }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="row">
                  <div class="col-md-4 border-end d-flex">
                    <div class="align-self-center mx-auto">
                      <span
                        class="badge bg-primary bg-opacity-25 text-primary"
                        >{{ elapsedTime(ticket.elapsed_time) }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-3 border-end d-flex">
                    <div class="align-self-center mx-auto">
                      <span
                        v-if="'submitted' === ticket.status"
                        class="badge bg-danger"
                        >Submitted</span
                      >
                      <span
                        v-if="'accepted' === ticket.status"
                        class="badge bg-warning"
                        >Accepted</span
                      >
                      <span
                        v-if="'working' === ticket.status"
                        class="badge bg-info"
                        >Working</span
                      >
                      <span
                        v-if="'resolved' === ticket.status"
                        class="badge bg-primary"
                        >Resolved</span
                      >
                      <span
                        v-if="'closed' === ticket.status"
                        class="badge bg-success"
                        >Closed</span
                      >
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="text-end">
                      <p class="small text-muted mb-0">
                        {{ timeAgo(ticket.updated_at) }}
                      </p>
                      <b class="d-block text-muted small">{{
                        localMachineTime(ticket.updated_at)
                      }}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <pagination
        v-if="paginator.current_page"
        :records="paginator.total_items"
        :per-page="10"
        v-model="current_page"
        :options="paginatorOptions"
      ></pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Storage from "../helpers/storage.js";
import Utils from "../helpers/utils";

import Breadcrumbs from "../components/Breadcrumbs.vue";
import RTPagination from "../components/RTPagination.vue";

export default {
  name: "Dashboard",
  components: { Breadcrumbs, Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      filter: {
        status: "",
        service: "",
        name: "",
      },
      paginator: {},
      services: [],
      tickets: [],
      current_page: 1,
      sortby: "id",
      sortdir: "desc",
    };
  },
  mounted() {
    this.fetch_services();
    this.fetch_tickets();
  },
  methods: {
    fetch_services() {
      axios
        .get(process.env.MIX_RT_UMS_URL + "/me/services?items=1000", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.services = response.data.data;
        });
    },

    fetch_tickets() {
      let url =
        "/api/v1/tickets/created?sortby=" +
        this.sortby +
        "&sortdir=" +
        this.sortdir +
        "&page=" +
        this.current_page;

      if ("" !== this.filter.status) {
        url += "&status=" + this.filter.status;
      }

      if ("" !== this.filter.service) {
        url += "&service=" + this.filter.service;
      }

      if ("" !== this.filter.name) {
        url += "&name=" + encodeURIComponent(this.filter.name);
      }

      axios
        .get(url, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.tickets = response.data.data;

          this.paginator = response.data.paginator;
        });
    },
    sortBy(column) {
      if (this.sortby === column) {
        this.sortdir = "asc" === this.sortdir ? "desc" : "asc";
      } else {
        this.sortby = column;
        this.sortdir = "desc";
      }

      this.fetch_tickets();
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    elapsedTime(dateString) {
      return Utils.elapsedTime(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    decHex(number) {
      return Utils.decHex(number);
    },
    hexDec(string) {
      return Utils.hexDec(string);
    },
  },
  watch: {
    current_page: function () {
      this.fetch_tickets();
    },
    "filter.status": function () {
      this.fetch_tickets();
    },
    "filter.service": function () {
      this.fetch_tickets();
    },
  },
};
</script>