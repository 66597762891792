<template>
  <div class="modal fade" tabindex="-1" id="scanQrCode">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Scan QR Code</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <qrcode-stream @init="onInit" @decode="onDecode" v-if="showCamera">
            <div class="alert alert-info text-center" v-if="loading">
              Loading...
            </div>
          </qrcode-stream>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue3-qrcode-reader'

export default {
  components: {
    QrcodeStream
  },
  data() {
    return {
      showCamera: false,
      loading: false,
      result: null
    };
  },
  mounted() {
    this.emitter.on("show_qrcode", (data) => {
      this.show_qrcode();
    });

    var qrcodeScanModal = document.getElementById('scanQrCode');
    qrcodeScanModal.addEventListener('hidden.bs.modal', function (event) {
      this.stop_scanning();
    }.bind(this))
  },
  methods: {
    async onInit(promise) {
      this.loading = true

      try {
        await promise;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    onDecode(result) {
      this.stop_scanning();
      this.emitter.emit("qrcode_result", result);
    },
    show_qrcode() {
      this.showCamera = true;
      $('#scanQrCode').modal('show');
    },
    stop_scanning() {
      this.showCamera = false;
      $('#scanQrCode').modal('hide');
    }
  }
}
</script>
