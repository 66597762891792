<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change ticket information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="organisation" class="form-label"
                >Ticket organisation</label
              ><select
                class="form-control"
                v-model="organisation_id"
                id="organisation"
              >
                <option value="">Please select</option>
                <option
                  v-for="organisation in organisations"
                  v-bind:key="organisation.id"
                  :value="organisation.id"
                >
                  {{ organisation.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="site" class="form-label">Ticket site</label
              ><select class="form-control" v-model="site_id" id="site">
                <option value="">Please select</option>
                <option
                  v-for="site in sites"
                  v-bind:key="site.id"
                  :value="site.id"
                >
                  {{ site.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="service" class="form-label">Ticket service</label
              ><select class="form-control" v-model="service_id" id="service">
                <option value="">Please select</option>
                <option
                  v-for="service in services"
                  v-bind:key="service.id"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                @click="update_information"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";
import Utils from "../../helpers/utils.js";

export default {
  name: "TicketInformation",
  props: ["id"],
  data() {
    return {
      organisation_id: "",
      site_id: "",
      service_id: "",
      organisations: [],
      sites: [],
      services: [],
    };
  },
  mounted() {
    this.emitter.on("ticket_details_loaded", (data) => {
      this.organisation_id = data.details ? data.details.organisation_id : "";
      this.site_id = data.details ? data.details.site_id : "";
      this.service_id = data.details ? data.details.service_id : "";
    });

    this.fetch_organisations();
    this.fetch_sites();
    this.fetch_services();
  },
  methods: {
    fetch_organisations() {
      axios
        .get(process.env.MIX_RT_UMS_URL + "/organizations?items=1000", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.organisations = response.data.data.sort((one, two) => {
            return one.name > two.name;
          });
        });
    },

    fetch_sites() {
      if ("" === this.organisation_id) {
        return;
      }

      if ("n/a" === this.organisation_id) {
        this.site_id = "";
        this.sites = [];
        return;
      }

      axios
        .get(
          process.env.MIX_RT_UMS_URL +
            "/organizations/" +
            this.organisation_id +
            "/sites?items=1000",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.sites = response.data.data.sort((one, two) => {
            return one.name > two.name;
          });
        });
    },

    fetch_services() {
      if ("" === this.organisation_id) {
        return;
      }

      if ("n/a" === this.organisation_id) {
        this.service_id = "";
        this.services = [];
        return;
      }

      axios
        .get(
          process.env.MIX_RT_UMS_URL +
            "/organizations/" +
            this.organisation_id +
            "/services?items=1000",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.services = response.data.data;
        });
    },

    update_information() {
      let app = this,
        data = {
          organisation_id: "",
          organisation_name: "",
          organisation_type: "",
          site_id: "",
          site_name: "",
          service_id: "",
          service_name: "",
        };

      for (let i = 0; i < this.organisations.length; i++) {
        if (this.organisations[i].id === this.organisation_id) {
          data.organisation_id = this.organisations[i].id;
          data.organisation_name = this.organisations[i].name;
          data.organisation_type = this.organisations[i].type;
        }
      }

      for (let i = 0; i < this.sites.length; i++) {
        if (this.sites[i].id === this.site_id) {
          data.site_id = this.sites[i].id;
          data.site_name = this.sites[i].name;
        }
      }

      for (let i = 0; i < this.services.length; i++) {
        if (this.services[i].id === this.service_id) {
          data.service_id = this.services[i].id;
          data.service_name = this.services[i].name;
        }
      }

      axios
        .patch(
          "/api/v1/tickets/" +
            this.hexDec(this.$route.params.ticket_id) +
            "/information",
          data,
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              app.emitter.emit("ticket_details_updated");

              app.emitter.emit("popup_alert", {
                type: "success",
                text: "Ticket was successfully updated!",
              });
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    elapsedTime(dateString) {
      return Utils.elapsedTime(dateString);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    decHex(number) {
      return Utils.decHex(number);
    },
    hexDec(string) {
      return Utils.hexDec(string);
    },
  },
  watch: {
    organisation_id: function () {
      this.site_id = "";
      this.service_id = "";

      this.fetch_sites();
      this.fetch_services();
    },
  },
};
</script>