<template>
  <div class="container p-0">
    <div
      class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      "
    >
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item">
                <router-link
                  to="/admin/departments"
                  class="text-decoration-none"
                  >Departments</router-link
                >
              </li>
              <li class="breadcrumb-item active text-dark">
                {{ department.name }}
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="card mb-2">
        <div class="card-header bg-white">
          <i class="bi bi-people-fill me-2"></i>
          Edit Department
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <h5 class="mb-0">Department details</h5>
              <p class="text-muted">Department details and members</p>
            </div>
            <div class="col-md-8">
              <div class="mb-3">
                <label class="form-label">Department Name</label>
                <input
                  v-model="department.name"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Members</label>
                <button
                  type="button"
                  class="btn btn-link p-0 float-end text-decoration-none"
                  data-bs-toggle="modal"
                  data-bs-target="#newInvitation"
                >
                  Invite
                </button>
                <ul class="list-group">
                  <li
                    v-for="member in department.members"
                    v-bind:key="member.id"
                    class="list-group-item d-flex align-items-center"
                  >
                    <div class="flex-shrink-0">
                      <img
                        :src="member.avatar"
                        :alt="member.name"
                        class="rounded-circle align-self-center"
                        width="36"
                        height="36"
                      />
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <b class="text-dark">{{ member.name }}</b>
                      <p class="small text-muted mb-0">
                        {{ member.email }}
                      </p>
                    </div>
                    <div class="flex-shrink-0">
                      <button type="button" class="btn btn-link p-0">
                        <i
                          class="bi bi-x-circle fs-5 text-danger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                          data-bs-original-title="Remove"
                          @click="delete_member_modal(member.id, member.name)"
                        ></i>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="float-start">
                <router-link
                  to="/admin/departments"
                  class="btn btn-outline-secondary"
                  >Close</router-link
                >
              </div>
              <div class="float-end">
                <button
                  type="button w-100"
                  class="btn btn-primary"
                  @click="save_department"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalNewInvitation id="newInvitation" />
  <ModalDeleteMember id="deleteMember" />
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

import ModalNewInvitation from "../../components/modals/NewInvitation.vue";
import ModalDeleteMember from "../../components/modals/DeleteMember.vue";

export default {
  name: "AdminDepartment",
  components: { ModalNewInvitation, ModalDeleteMember },
  data() {
    return {
      department: {
        id: this.$route.params.department_id,
        name: "",
        members: [],
      },
    };
  },
  mounted() {
    this.emitter.emit("department_details_loaded", {
      department_id: this.department.id,
    });

    this.emitter.on("member_delete_confirm", (data) => {
      for (let member in this.department.members) {
        if (this.department.members[member].id === data.member_id) {
          this.department.members.splice(member, 1);
        }
      }
    });

    this.fetch_department();
  },
  methods: {
    fetch_department() {
      axios
        .get("/api/v1/departments/" + this.department.id, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.department = response.data;
        });
    },
    save_department() {
      var app = this;

      axios
        .patch("/api/v1/departments/" + this.department.id, this.department, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Department was successfully updated!",
          });

          this.fetch_department();
        });
    },
    delete_member_modal(member_id, member_name) {
      this.emitter.emit("member_details_loaded", {
        member_id: member_id,
        member_name: member_name,
      });

      var deleteMemberModal = new bootstrap.Modal(
        document.getElementById("deleteMember"),
        null
      );
      deleteMemberModal.show();
    },
  },
  watch: {},
};
</script>