<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Invitation</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Email</label>
              <input
                type="text"
                id="name"
                class="form-control"
                v-model="email"
                @keyup="validate"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
                @click="send_invitation"
              >
                Send invitation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "NewInvitation",
  props: ["id"],
  data() {
    return {
      department_id: "",
      email: "",
      valid_input: false,
    };
  },
  mounted() {
    this.emitter.on("department_details_loaded", (data) => {
      this.department_id = data.department_id;
    });
  },
  methods: {
    validate() {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.valid_input = re.test(String(this.email).toLowerCase());
    },

    send_invitation() {
      let app = this;

      axios
        .post(
          "/api/v1/users/invitation",
          {
            department: this.department_id,
            email: this.email,
          },
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.emitter.emit("invitation_sent");

          let newInvitationModal = bootstrap.Modal.getInstance(
            document.getElementById(this.id)
          );
          newInvitationModal.hide();

          this.emitter.emit("popup_alert", {
            type: "success",
            text: "Invitation to " + this.email + " was successfully sent!",
          });
        })
        .catch(function (error) {
          if (error.response) {
            let errorMsg = "";

            for (let key in error.response.data) {
              errorMsg += error.response.data[key].join(" ");
            }

            app.emitter.emit("popup_alert", {
              type: "danger",
              text: errorMsg,
            });
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>