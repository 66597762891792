<template>
  <div class="d-flex flex-row">
    <div
      class="
        flex-column flex-shrink-0
        p-3
        bg-white
        vh-100
        p-0
        border-end
        d-none d-lg-flex d-xl-flex d-xxl-flex
      "
      style="width: 280px"
    >
      <router-link
        to="/admin"
        class="
          d-flex
          align-items-center
          link-dark
          text-decoration-none text-center
        "
      >
        <span class="fs-4 fw-bold"
          ><i class="bi bi-life-preserver me-2"></i>Support</span
        >
        <span
          class="
            badge
            bg-white
            ms-auto
            text-dark
            border
            fw-bold
            align-self-center
          "
          >Admin</span
        >
      </router-link>
      <select v-model="filter.service" class="form-select my-3">
        <option value="">All Services</option>
        <option
          v-for="service in services"
          v-bind:key="service.id"
          :value="service.id"
        >
          {{ service.name }}
        </option>
      </select>
      <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item">
          <router-link
            to="/admin"
            :class="
              'dashboard' === active_menu
                ? 'nav-link d-flex bg-primary bg-opacity-25'
                : 'nav-link d-flex link-dark'
            "
            @click="setActiveMenu('dashboard')"
            ><i class="bi bi-speedometer me-2"></i>Dashboard</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            to="/admin/tickets"
            :class="
              'tickets' === active_menu
                ? 'nav-link d-flex bg-primary bg-opacity-25'
                : 'nav-link d-flex link-dark'
            "
            @click="setActiveMenu('tickets')"
            ><i class="bi bi-inbox-fill me-2"></i>Tickets<span
              class="
                badge
                bg-white
                ms-auto
                text-dark
                border
                fw-bold
                align-self-center
              "
              >{{ stats.tickets }}</span
            ></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            to="/admin/replies"
            class="nav-link d-flex"
            :class="
              'replies' === active_menu
                ? 'bg-primary bg-opacity-25'
                : 'link-dark'
            "
            @click="setActiveMenu('replies')"
            ><i class="bi bi-chat-square-dots-fill me-2"></i>Canned replies<span
              class="
                badge
                bg-white
                ms-auto
                text-dark
                border
                fw-bold
                align-self-center
              "
              >{{ stats.canned_replies }}</span
            ></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            to="/admin/departments"
            class="nav-link d-flex"
            :class="
              'departments' === active_menu
                ? 'bg-primary bg-opacity-25'
                : 'link-dark'
            "
            @click="setActiveMenu('departments')"
            ><i class="bi bi-people-fill me-2"></i>Departments<span
              class="
                badge
                bg-white
                ms-auto
                text-dark
                border
                fw-bold
                align-self-center
              "
              >{{ stats.departments }}</span
            ></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            to="/admin/categories"
            class="nav-link d-flex"
            :class="
              'categories' === active_menu
                ? 'bg-primary bg-opacity-25'
                : 'link-dark'
            "
            @click="setActiveMenu('categories')"
            ><i class="bi bi-menu-button-wide-fill me-2"></i>Categories<span
              class="
                badge
                bg-white
                ms-auto
                text-dark
                border
                fw-bold
                align-self-center
              "
              >{{ stats.categories }}</span
            ></router-link
          >
        </li>
      </ul>
      <ul class="nav nav-pills flex-column mb-2">
        <li class="nav-item">
          <a href="#" class="nav-link link-dark"
            ><i class="bi bi-book-half me-2"></i>Documentation</a
          >
        </li>
        <li class="nav-item">
          <a href="/" class="nav-link link-dark"
            ><i class="bi bi-life-preserver me-2"></i>User Portal</a
          >
        </li>
        <li class="nav-item">
          <button
            class="btn btn-link float-end mt-2 link-dark p-0"
            @click="logout"
          >
            <i class="bi bi-power me-2"></i>
          </button>
          <a href="/account" class="nav-link link-dark"
            ><i class="bi bi-person-circle me-2"></i
            >{{ currentUser.first_name + " " + currentUser.last_name }}</a
          >
        </li>
      </ul>
      <span class="small text-center text-muted border-top pt-2">
        © 2022 REMOTE TECH LTD
      </span>
    </div>

    <div class="w-100 vh-100 overflow-scroll hhfix">
      <router-view />
    </div>
  </div>
</template>
  
<script>
import axios from "axios";

import Storage from "../helpers/storage.js";

export default {
  name: "Admin",
  components: {},
  data() {
    return {
      active_menu: "dashboard",
      currentUser: process.appdata.user,
      filter: {
        service: "",
      },
      services: [],
      stats: {
        tickets: 0,
        canned_replies: 0,
        departments: 0,
        categories: 0,
      },
    };
  },
  created() {
    try {
      if (
        process.env.MIX_RT_NUMBER !==
        process.appdata.user.rootOrganization.company_number
      ) {
        window.location.href = "/";
      }
    } catch (err) {
      window.location.href = "/";
    }

    this.active_menu = this.$route.meta.submenu;
  },
  mounted() {
    this.emitter.on("ticket_created", (data) => {
      this.fetch_stats();
    });

    this.emitter.on("department_created", (data) => {
      this.fetch_stats();
    });

    this.emitter.on("category_created", (data) => {
      this.fetch_stats();
    });

    this.emitter.on("canned_reply_created", (data) => {
      this.fetch_stats();
    });

    this.fetch_stats();
    this.fetch_services();
  },
  methods: {
    fetch_services() {
      axios
        .get(process.env.MIX_RT_UMS_URL + "/services?items=1000", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.services = response.data.data;
        });
    },

    fetch_stats() {
      axios
        .get("/api/v1/adminstats?service=" + this.filter.service, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.stats = response.data;
        });
    },

    setActiveMenu(menu) {
      this.active_menu = menu;
    },
    logout() {
      process.appdata.logout();
    },
  },
  watch: {
    "filter.service": function () {
      this.fetch_stats();
    },
  },
};
</script>