<template>
  <div class="card mb-2">
    <div class="card-header bg-white">
      <i class="bi bi-clock me-2"></i>
      Ticket Status History
    </div>
    <div v-if="0 < history.length" class="card-body">
      <div
        v-for="item in history"
        v-bind:key="item.id"
        class="d-flex align-items-center border-bottom py-2"
      >
        <div class="flex-shrink-0">
          <img
            :src="item.creator_avatar"
            alt="twbs"
            class="rounded-circle align-self-center"
            width="36"
            height="36"
          />
        </div>
        <div class="flex-grow-1 ms-3">
          <b class="text-dark">{{ item.creator_name }}</b>
          <p class="small text-muted mb-0">
            {{ item.creator_organisation }}
          </p>
        </div>

        <div class="flex-grow-1 text-center">
          <span
            v-if="'submitted' === item.status"
            class="badge bg-danger bg-opacity-25 text-danger"
            >Submitted</span
          >
          <span
            v-if="'accepted' === item.status"
            class="badge bg-warning bg-opacity-25 text-warning"
            >Accepted</span
          >
          <span
            v-if="'working' === item.status"
            class="badge bg-info bg-opacity-25 text-info"
            >Working</span
          >
          <span
            v-if="'resolved' === item.status"
            class="badge bg-primary bg-opacity-25 text-primary"
            >Resolved</span
          >
          <span
            v-if="'closed' === item.status"
            class="badge bg-success bg-opacity-25 text-success"
            >Closed</span
          >
        </div>

        <div class="flex-grow-1 text-end">
          <b class="small">{{ timeAgo(item.created_at) }}</b>
          <p class="small text-muted mb-0">
            {{ localMachineTime(item.created_at) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";
import Utils from "../../helpers/utils.js";

export default {
  name: "TicketStatusHistory",
  components: {},
  computed: {
    umsURL() {
      return "https://" + process.env.MIX_RT_UMS_DOMAIN;
    },
  },
  data() {
    return {
      ticket_id: this.hexDec(this.$route.params.ticket_id),
      history: [],
    };
  },
  mounted() {
    this.fetch_status_history();
  },
  methods: {
    fetch_status_history() {
      axios
        .get("/api/v1/tickets/" + this.ticket_id + "/statushistory", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.history = response.data;
        });
    },
    elapsedTime(dateString) {
      return Utils.elapsedTime(dateString);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    decHex(number) {
      return Utils.decHex(number);
    },
    hexDec(string) {
      return Utils.hexDec(string);
    },
  },
  watch: {},
};
</script>