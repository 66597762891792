<template>
  <Breadcrumbs :breaditems="[{ title: 'My account' }]" />
  <div class="container">
    <div class="row g-2">
      <div class="col-md-3 mt-2">
        <div class="card">
          <div class="card-header bg-light">Menu</div>
          <div class="list-group list-group-flush">
            <router-link
              to="/account"
              :class="
                ('overview' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('overview')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-app-indicator"></i>
                </div>
                <div class="flex-grow-1 ms-3">My Account</div>
              </div>
            </router-link>
            <router-link
              :to="'/account/logs'"
              :class="
                ('logs' === active_menu ? 'active ' : '') +
                'list-group-item list-group-item-action d-flex gap-2 align-items-center'
              "
              @click="setActiveMenu('logs')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-people-fill"></i>
                </div>
                <div class="flex-grow-1 ms-3">Activity Logs</div>
              </div>
              <span
                class="badge bg-light ms-auto text-dark border fw-normal"
              ></span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";

import RTSocket from "../helpers/rt-socket";

export default {
  name: "MyAccount",
  components: { Breadcrumbs },
  data() {
    return {
      active_menu: "overview",
    };
  },
  created() {
    this.active_menu = this.$route.meta.submenu;

    let sock = RTSocket.get(
      "ws://socks.lvh.me:8080?authorization=eBWBcaFRnW3NI1NuyUWI2iEelOKp5h6e&user=4427edc2-527f-47ab-a4db-eb0baedf3227"
    );

    console.log(sock);
    sock.send(JSON.stringify({ a: "b" }));
  },
  mounted() {},
  methods: {
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
  },
};
</script>