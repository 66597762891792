<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change ticket category</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label class="form-label">Ticket category</label>
              <select
                v-model="category"
                class="form-select"
                aria-label="Default select example"
                v-on:change="validate"
              >
                <option value="">Select an Option</option>
                <option
                  v-for="category in categories"
                  v-bind:key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
                @click="update_category"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";

export default {
  name: "TicketCategory",
  props: ["id"],
  data() {
    return {
      ticket_id: "",
      category: "",
      categories: [],
    };
  },
  mounted() {
    this.emitter.on("ticket_details_loaded", (data) => {
      this.ticket_id = data.details ? data.details.id : "";
      this.category = data.details ? data.details.category : "";

      this.fetch_categories();

      this.validate();
    });
  },
  methods: {
    validate() {
      this.valid_input = "" !== this.category;
    },

    fetch_categories() {
      axios
        .get("/api/v1/categories/list", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.categories = response.data;
        });
    },

    update_category() {
      let app = this;

      axios
        .patch(
          "/api/v1/tickets/" + this.ticket_id + "/category",
          {
            id: this.ticket_id,
            category: this.category,
          },
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then(
          (response) => {
            if (200 === response.status) {
              let ticketCategoryModal = bootstrap.Modal.getInstance(
                document.getElementById(this.id)
              );
              ticketCategoryModal.hide();

              this.emitter.emit("popup_alert", {
                type: "success",
                text: "Ticket was successfully updated!",
              });

              app.emitter.emit("ticket_details_updated");
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>