import axios from "axios";
import { createApp } from "vue";
import mitt from "mitt";
import VueTelInput from "vue3-tel-input";

import App from "./App.vue";
import router from "./router";

import Storage from "./helpers/storage";
import RTAuth from "./helpers/rt-auth";

/* Import the vanillajs-datepicker stylesheet */
import "../node_modules/vanillajs-datepicker/dist/css/datepicker.min.css";

/* Import the custom stylesheet */
import "./assets/styles.css";

const Emitter = mitt();

process.appdata = {};
process.appdata.user = Storage.get("user", true);
process.appdata.tokens = Storage.get("tokens", true);
process.appdata.adminPage = false;

/* Check every minute if user's token is expired or user's session is revoked */
clearInterval(process.appdata.loginCheck);
process.appdata.loginCheck = setInterval(() => {
    process.appdata.getme();
}, 60000);


/* Login localy */
process.appdata.umslogin = (callback) => {
    var tokens = Storage.get("tokens"),
        user = Storage.get("user");

    axios.post("/api/v1/umslogin", {
        "tokens": tokens,
        "user": user
    }).then(function (response) {
        if (null !== response.data.token) {
            Storage.set("session_token", response.data.token);

            if (callback) {
                callback();
            } else {
                window.location.href = "/";
            }
        }
    }).catch(function (error) {
        console.log(error);
    });
};

/* Remove storage data and redirect to logout URL */
process.appdata.logout = () => {
    Storage.remove("tokens");
    Storage.remove("user");

    window.location.href = "https://" + process.env.MIX_RT_UMS_DOMAIN + "/logout?client_id=" + process.env.MIX_RT_UMS_CLIENT_ID
};


/* Get information about current user from UMS API */
process.appdata.getme = (callback) => {
    let tokens = Storage.get("tokens", true);

    if (!tokens || new Date() > tokens.ttl) {
        RTAuth.requestToken();
    }

    axios.get(process.env.MIX_RT_UMS_URL + "/me", {
        headers: {
            Authorization: tokens.access_token
        },
    }).then((response) => {
        Storage.set("user", JSON.stringify(response.data));

        if (callback) {
            callback();
        }
    }).catch(function (error) {
        RTAuth.refreshToken();
    });
}


/* Check for ongoing login attempt */
if (
    window.location.search.includes("code=") &&
    window.location.search.includes("state=")
) {
    RTAuth.accessToken(() => {
        process.appdata.getme(() => {
            process.appdata.umslogin();
        })
    });
} else {
    try {
        if (new Date() > process.appdata.tokens.ttl) {
            RTAuth.refreshToken(function () {
                process.appdata.umslogin(function () {
                    // Finally create the application instance after all information is retrieved
                    const app = createApp(App);
                    app.config.globalProperties.emitter = Emitter;
                    app.use(router);
                    app.use(VueTelInput);
                    app.mount("#app");
                });
            });
        } else {
            // Finally create the application instance after all information is retrieved
            const app = createApp(App);
            app.config.globalProperties.emitter = Emitter;
            app.use(router);
            app.use(VueTelInput);
            app.mount("#app");
        }
    } catch (err) {
        // console.log(err);
        RTAuth.requestToken();
    }
}