<template>
  <div class="container p-0">
    <div
      class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      "
    >
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item active text-dark" aria-current="page">
                Categories
              </li>
            </ol>
          </nav>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text bg-white border-end-0"
              ><i class="bi bi-search"></i
            ></span>
            <input
              v-model="filter.name"
              type="text"
              class="form-control border-start-0"
              placeholder="Search..."
              v-on:keyup.enter="fetch_categories()"
            />
          </div>
        </div>
        <div class="">
          <button
            type="button"
            class="btn btn-dark"
            data-bs-toggle="modal"
            data-bs-target="#newCategory"
          >
            + <span class="d-none d-xl-inline d-xxl-inline">Add Category</span>
          </button>
        </div>
      </div>

      <div class="d-flex mb-2">
        <div class="me-2">
          <div class="input-group">
            <span
              class="input-group-text border-end-0 bg-white"
              id="basic-addon1"
              ><i class="bi bi-people"></i
            ></span>
            <select
              v-model="filter.department"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 200px"
            >
              <option disabled="">Department</option>
              <option value="">All Departments</option>
              <option
                v-for="department in departments"
                v-bind:key="department.id"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="card mb-2">
        <div v-if="!categories || 0 === categories.length" class="card-body">
          <div class="text-center">
            <img src="/images/empty-categories.svg" class="img-fluid py-5" />
          </div>
        </div>

        <div v-else class="list-group list-group-flush">
          <router-link
            v-for="category in categories"
            v-bind:key="category.id"
            :to="'/admin/categories/' + category.id"
            class="list-group-item list-group-item-action"
          >
            <div class="d-flex align-items-center">
              <div
                class="
                  flex-shrink-0
                  bg-white
                  border
                  bg-opacity-25
                  rounded-circle
                  d-flex
                "
                style="height: 45px; width: 45px"
              >
                <i
                  class="
                    bi bi-menu-button-wide-fill
                    fs-5
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-dark
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark small">{{ category.name }}</b>
                <p class="small text-muted mb-0">
                  <span
                    v-for="department in category.departments"
                    v-bind:key="department.id"
                    class="badge bg-primary bg-opacity-25 text-primary me-1"
                    >{{ department.name }}</span
                  >
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <ModalNewCategory id="newCategory" />
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

import ModalNewCategory from "../../components/modals/NewCategory.vue";

export default {
  name: "AdminCategories",
  components: { ModalNewCategory },
  data() {
    return {
      filter: {
        department: "",
        name: "",
      },
      departments: [],
      categories: [],
    };
  },
  mounted() {
    this.emitter.on("category_created", () => {
      this.fetch_categories();
    });

    this.fetch_departments();
    this.fetch_categories();
  },
  methods: {
    fetch_departments() {
      axios
        .get("/api/v1/departments/list", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.departments = response.data;
        });
    },
    fetch_categories() {
      axios
        .get("/api/v1/categories?department=" + this.filter.department, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.categories = response.data;

          console.log(this.categories.length);
        });
    },
  },
  watch: {
    "filter.department": function () {
      this.fetch_categories();
    },
  },
};
</script>