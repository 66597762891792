<template>
  <div class="card mb-2">
    <div class="card-header bg-white">
      <i class="bi bi-app-indicator me-2"></i>
      Wiki
    </div>
    <div class="card-body">
      <h5 class="mb-0">Ticket status steps</h5>
      <p class="small">
        When a ticket is submitted, the following status stages are used to
        track progress in the ticketing system.
      </p>

      <table class="table table-bordered mb-0">
        <thead>
          <tr>
            <th scope="col">Status</th>
            <th scope="col">Description</th>
            <th scope="col">Measures</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <span
                class="badge bg-danger bg-opacity-25 text-danger"
                style="width: 90px"
                >Submitted</span
              >
            </td>
            <td>When a ticket is raised</td>
            <td class="bg-light"></td>
          </tr>
          <tr>
            <td class="text-center">
              <span
                class="badge bg-warning bg-opacity-25 text-warning"
                style="width: 90px"
                >Accepted</span
              >
            </td>
            <td>When we make contact with the ticket owner</td>
            <td>Response time</td>
          </tr>
          <tr>
            <td class="text-center">
              <span
                class="badge bg-info bg-opacity-25 text-info"
                style="width: 90px"
                >Working</span
              >
            </td>
            <td>When we are investigating the issue</td>
            <td>Update frequency</td>
          </tr>
          <tr>
            <td class="text-center">
              <span
                class="badge bg-primary bg-opacity-25 text-primary"
                style="width: 90px"
                >Resolved</span
              >
            </td>
            <td>
              When a fix has been identified and the ticket owner is notified
            </td>
            <td>Resolution time</td>
          </tr>
          <tr>
            <td class="text-center">
              <span
                class="badge bg-success bg-opacity-25 text-success"
                style="width: 90px"
                >Closed</span
              >
            </td>
            <td>When the ticket owner confirms the fix</td>
            <td class="bg-light"></td>
          </tr>
          <tr>
            <td class="text-center">
              <span
                class="badge bg-secondary bg-opacity-25 text-secondary"
                style="width: 90px"
                >On Hold</span
              >
            </td>
            <td>When further information is awaited</td>
            <td class="bg-light"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketWiki",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    fetch_ticket() {},
  },
  watch: {},
};
</script>