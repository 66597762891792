<template>
  <div class="container p-0">
    <div
      class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      "
    >
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item active text-dark" aria-current="page">
                Dashboard
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="card-group text-center">
        <div class="card">
          <router-link
            to="/admin/tickets?status=submitted"
            class="card-body list-group-item-action"
          >
            <div class="d-flex">
              <div
                class="
                  flex-shrink-0
                  bg-danger bg-opacity-25
                  rounded-circle
                  d-flex
                "
                style="height: 44px; width: 44px"
              >
                <i
                  class="
                    bi bi-life-preserver
                    fs-4
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-danger
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="float-start text-start">
                  <span class="text-black fw-bold mb-0 d-block">{{
                    tickets_count.submitted
                  }}</span
                  ><span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >Submitted Tickets</span
                  >
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="card">
          <router-link
            to="/admin/tickets?status=accepted"
            class="card-body list-group-item-action"
          >
            <div class="d-flex">
              <div
                class="
                  flex-shrink-0
                  bg-warning bg-opacity-25
                  rounded-circle
                  d-flex
                "
                style="height: 44px; width: 44px"
              >
                <i
                  class="
                    bi bi-life-preserver
                    fs-4
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-warning
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="float-start text-start">
                  <span class="text-black fw-bold mb-0 d-block">{{
                    tickets_count.accepted
                  }}</span
                  ><span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >Accepted Tickets</span
                  >
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="card">
          <router-link
            to="/admin/tickets?status=working"
            class="card-body list-group-item-action"
          >
            <div class="d-flex">
              <div
                class="
                  flex-shrink-0
                  bg-info bg-opacity-25
                  rounded-circle
                  d-flex
                "
                style="height: 44px; width: 44px"
              >
                <i
                  class="
                    bi bi-life-preserver
                    fs-4
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-info
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="float-start text-start">
                  <span class="text-black fw-bold mb-0 d-block">{{
                    tickets_count.working
                  }}</span
                  ><span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >Working Tickets</span
                  >
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="card">
          <router-link
            to="/admin/tickets?status=resolved"
            class="card-body list-group-item-action"
          >
            <div class="d-flex">
              <div
                class="
                  flex-shrink-0
                  bg-primary bg-opacity-25
                  rounded-circle
                  d-flex
                "
                style="height: 44px; width: 44px"
              >
                <i
                  class="
                    bi bi-life-preserver
                    fs-4
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-primary
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="float-start text-start">
                  <span class="text-black fw-bold mb-0 d-block">{{
                    tickets_count.resolved
                  }}</span
                  ><span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >Resolved Tickets</span
                  >
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="card">
          <router-link
            to="/admin/tickets?status=closed"
            class="card-body list-group-item-action"
          >
            <div class="d-flex">
              <div
                class="
                  flex-shrink-0
                  bg-success bg-opacity-25
                  rounded-circle
                  d-flex
                "
                style="height: 44px; width: 44px"
              >
                <i
                  class="
                    bi bi-life-preserver
                    fs-4
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-success
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <div class="float-start text-start">
                  <span class="text-black fw-bold mb-0 d-block">{{
                    tickets_count.closed
                  }}</span
                  ><span
                    class="text-muted d-block text-uppercase"
                    style="font-size: 12px"
                    >Closed Tickets</span
                  >
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- Chart -->
      <div class="card mt-2" id="activityStatsControls">
        <div class="card-header bg-light">
          <span>Tickets</span>
          <span class="float-end">
            <button
              type="button"
              @click="setChartInterval('day')"
              :class="
                'day' === chartInterval
                  ? 'badge bg-primary'
                  : 'badge bg-light text-muted'
              "
            >
              D
            </button>
            <button
              type="button"
              @click="setChartInterval('week')"
              :class="
                'week' === chartInterval
                  ? 'badge bg-primary'
                  : 'badge bg-light text-muted'
              "
            >
              W
            </button>
            <button
              type="button"
              @click="setChartInterval('month')"
              :class="
                'month' === chartInterval
                  ? 'badge bg-primary'
                  : 'badge bg-light text-muted'
              "
            >
              M
            </button>
            <button
              type="button"
              @click="setChartInterval('year')"
              :class="
                'year' === chartInterval
                  ? 'badge bg-primary'
                  : 'badge bg-light text-muted'
              "
            >
              Y
            </button>
          </span>
        </div>
        <div class="card-body" id="activityStatsContainer">
          <div id="activityStatsPlot"></div>
        </div>
      </div>
      <!-- Chart end -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";

export default {
  name: "AdminDashboard",
  components: {},
  data() {
    return {
      tickets_count: {
        submitted: 0,
        accepted: 0,
        working: 0,
        resolved: 0,
        closed: 0,
      },
      filter: {
        service: "",
      },
      series: [],
      chartInterval: "day",
    };
  },
  mounted() {
    this.fetch_stats();
  },
  methods: {
    fetch_stats() {
      axios
        .get("/api/v1/admindashboardstats?service=" + this.filter.service + "&interval=" + this.chartInterval, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.tickets_count = response.data.tickets_count;
          this.series = response.data.series;

          var chart = null,
            dateList = [],
            valueList = [];

          try {
            chart.dispose();
          } catch (err) {}

          for (let item in this.series) {
            dateList.push(this.series[item].label);
            valueList.push(this.series[item].value);
          }

          /* Dirty fix that works */
          jQuery("#activityStatsPlot").removeAttr("_echarts_instance_");

          chart = echarts.init(document.getElementById("activityStatsPlot"));

          var options = {
            toolbox: {
              show: true,
              feature: {
                dataZoom: {
                  yAxisIndex: "none",
                },
                magicType: { type: ["line"] },
                restore: {},
                saveAsImage: {},
              },
              top: "5%",
            },
            grid: [{ left: "3%", top: "3%", width: "96%", height: "90%" }],
            tooltip: {
              trigger: "axis",
            },
            xAxis: {
              name: "Interval",
              data: dateList,
            },
            yAxis: {
              name: "Count",
              type: "value",
            },
            series: {
              type: "line",
              showSymbol: false,
              data: valueList,
            },
          };

          options && chart.setOption(options);
        });
    },

    setChartInterval(interval) {
      this.chartInterval = interval;
      this.fetch_stats();
    },
  },
  watch: {},
};
</script>