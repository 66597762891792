export default {
    name: "RTSocket",
    version: "0.0.1",
    socket: null,
    get: () => {
        return {
            socket: function () {
                if (socket == null) {
                    socket = new WebSocket("ws://localhost:8081/index.ashx");
                    socket.onclose = function (evt) { console.log('Closed'); }
                    socket.extraParameter = null;
                }
                return socket;
            },
            send: function (data, callback) {
                var localSocket = connection.socket();
                localSocket.extraParameter = new Date().toString();
                localSocket.onmessage = (evt) => {
                    callback(evt.data, data, extraParameter);
                };
                localSocket.originalDataSent = data;
                localSocket.send(data);
            }
        }
    }
    /*
    get(url) {
        if (null === this.socket) {
            this.socket = new WebSocket(url);

            this.socket.onopen = function (e) {
                console.log("Connection established!");
            };
    
            this.socket.onmessage = function (e) {
                console.log(e.data);
            };

            this.connect(() => {
                return this.socket;
            });
        } else {
            this.connect(() => {
                return this.socket;
            });
        }
    },

    connect(callback) {
        setTimeout(() => {
            if (this.socket.readyState === 1) {
                if (callback != null) {
                    callback();
                }
            } else {
                this.connect(this.socket, callback);
            }
        }, 50);
    },
    */
}