<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Remove Member</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger text-center">
                Are you sure you to remove <b>{{ member_name }}</b
                >?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-danger w-100" @click="delete_member()">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteMember",
  props: ["id"],
  data() {
    return {
      member_id: null,
      member_name: "",
    };
  },
  mounted() {
    this.emitter.on("member_details_loaded", (data) => {
      this.member_id = data.member_id;
      this.member_name = data.member_name;
    });
  },
  methods: {
    delete_member() {
      this.emitter.emit("member_delete_confirm", {
        member_id: this.member_id,
      });

      let deleteMemberModal = bootstrap.Modal.getInstance(
        document.getElementById(this.id)
      );
      deleteMemberModal.hide();

      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Member was successfully deleted!",
      });
    },
  },
};
</script>