import { createRouter, createWebHistory } from "vue-router";

// Import all page components
import Dashboard from "./pages/Dashboard.vue";
import NewTicketPage from "./pages/NewTicket.vue";
import TicketPage from "./pages/Ticket.vue";

import TicketDiscussion from "./pages/fragments/TicketDiscussion.vue";
import TicketStatusHistory from "./pages/fragments/TicketStatusHistory.vue";
import TicketInformation from "./pages/fragments/TicketInformation.vue";
import TicketWiki from "./pages/fragments/TicketWiki.vue";

import MyAccountPage from "./pages/MyAccount.vue";
import MyAccountOverview from "./pages/fragments/MyAccountOverview.vue";
import MyAccountLogs from "./pages/fragments/MyAccountLogs.vue";
import MyAccountLog from "./pages/fragments/MyAccountLog.vue";

import AdminPage from "./pages/Admin.vue";
import AdminDashboard from "./pages/fragments/AdminDashboard.vue";

import AdminTickets from "./pages/fragments/AdminTickets.vue";
import AdminTicket from "./pages/fragments/AdminTicket.vue";
import AdminNewTicket from "./pages/fragments/AdminNewTicket.vue";
import AdminTicketDiscussion from "./pages/fragments/AdminTicketDiscussion.vue";
import AdminTicketInformation from "./pages/fragments/AdminTicketInformation.vue";
import AdminTicketStatusHistory from "./pages/fragments/AdminTicketStatusHistory.vue";

import AdminReplies from "./pages/fragments/AdminReplies.vue";
import AdminReply from "./pages/fragments/AdminReply.vue";
import AdminNewReply from "./pages/fragments/AdminNewReply.vue";

import AdminDepartments from "./pages/fragments/AdminDepartments.vue";
import AdminDepartment from "./pages/fragments/AdminDepartment.vue";

import AdminCategories from "./pages/fragments/AdminCategories.vue";
import AdminCategory from "./pages/fragments/AdminCategory.vue";

import NotFound from "./pages/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard - Support",
    }
  },
  {
    path: "/tickets/new",
    name: "NewTicket",
    component: NewTicketPage,
    meta: {
      title: "New Ticket - Support",
    }
  },
  {
    path: "/tickets/:ticket_id",
    name: "Ticket",
    component: TicketPage,
    meta: {
      title: "Ticket - Support",
    }, children: [
      {
        path: "",
        name: "TicketDiscussion",
        component: TicketDiscussion,
        meta: {
          title: "Discussion - REMOTE TECH LTD",
          submenu: "discussion"
        }
      },
      {
        path: "information",
        name: "TicketInformation",
        component: TicketInformation,
        meta: {
          title: "Information - REMOTE TECH LTD",
          submenu: "information"
        }
      },
      {
        path: "statushistory",
        name: "TicketStatusHistory",
        component: TicketStatusHistory,
        meta: {
          title: "Status History - REMOTE TECH LTD",
          submenu: "statushistory"
        }
      },
      {
        path: "wiki",
        name: "TicketWiki",
        component: TicketWiki,
        meta: {
          title: "Wiki - REMOTE TECH LTD",
          submenu: "wiki"
        }
      }]
  },
  {
    path: "/account",
    name: "MyAccountPage",
    component: MyAccountPage,
    meta: {
      title: "My Account - REMOTE TECH LTD"
    }, children: [
      {
        path: "",
        name: "MyAccountOverview",
        component: MyAccountOverview,
        meta: {
          title: "Overview - REMOTE TECH LTD",
          submenu: "overview"
        }
      },
      {
        path: "logs",
        name: "MyAccountLogs",
        component: MyAccountLogs,
        meta: {
          title: "Logs - REMOTE TECH LTD",
          submenu: "logs"
        }
      },
      {
        path: "logs/:log",
        name: "MyAccountLog",
        component: MyAccountLog,
        meta: {
          title: "Log - REMOTE TECH LTD",
          submenu: "logs"
        }
      },
    ]
  },
  {
    path: "/admin",
    name: "AdminPage",
    component: AdminPage,
    meta: {
      title: "Admin - REMOTE TECH LTD"
    }, children: [
      {
        path: "",
        name: "AdminDashboard",
        component: AdminDashboard,
        meta: {
          title: "Dashboard - REMOTE TECH LTD",
          submenu: "dashboard"
        }
      },
      {
        path: "tickets",
        name: "AdminTickets",
        component: AdminTickets,
        meta: {
          title: "Tickets - REMOTE TECH LTD",
          submenu: "tickets"
        }
      },
      {
        path: "newticket",
        name: "AdminNewTicket",
        component: AdminNewTicket,
        meta: {
          title: "New ticket - REMOTE TECH LTD",
          submenu: "tickets"
        }
      },
      {
        path: "tickets/:ticket_id",
        name: "AdminTicket",
        component: AdminTicket,
        meta: {
          title: "Tickets - REMOTE TECH LTD",
          submenu: "tickets"
        },
        children: [
          {
            path: "",
            name: "AdminTicketDiscussion",
            component: AdminTicketDiscussion,
            meta: {
              title: "Ticket - REMOTE TECH LTD",
              submenu: "discussion"
            }
          },
          {
            path: "statushistory",
            name: "AdminTicketStatusHistory",
            component: AdminTicketStatusHistory,
            meta: {
              title: "Status History - REMOTE TECH LTD",
              submenu: "statushistory"
            }
          },
          {
            path: "information",
            name: "AdminTicketInformation",
            component: AdminTicketInformation,
            meta: {
              title: "Ticket - REMOTE TECH LTD",
              submenu: "information",
            }
          }
        ]
      },
      {
        path: "replies",
        name: "AdminReplies",
        component: AdminReplies,
        meta: {
          title: "Canned Replies - REMOTE TECH LTD",
          submenu: "replies"
        }
      },
      {
        path: "newreply",
        name: "AdminNewReply",
        component: AdminNewReply,
        meta: {
          title: "New Canned Reply - REMOTE TECH LTD",
          submenu: "replies"
        }
      },
      {
        path: "replies/:reply_id",
        name: "AdminReply",
        component: AdminReply,
        meta: {
          title: "Canned Reply - REMOTE TECH LTD",
          submenu: "replies"
        }
      },
      {
        path: "departments",
        name: "AdminDepartments",
        component: AdminDepartments,
        meta: {
          title: "Departments - REMOTE TECH LTD",
          submenu: "departments"
        }
      },
      {
        path: "departments/:department_id",
        name: "AdminDepartment",
        component: AdminDepartment,
        meta: {
          title: "Departments - REMOTE TECH LTD",
          submenu: "departments"
        }
      },
      {
        path: "categories",
        name: "AdminCategories",
        component: AdminCategories,
        meta: {
          title: "Categories - REMOTE TECH LTD",
          submenu: "categories"
        }
      },
      {
        path: "categories/:category_id",
        name: "AdminCategory",
        component: AdminCategory,
        meta: {
          title: "Category - REMOTE TECH LTD",
          submenu: "categories"
        }
      }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Not Found - REMOTE TECH LTD",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "REMOTE TECH LTD";

  if (-1 !== toRoute.fullPath.indexOf("admin")) {
    process.appdata.adminPage = true;
  }

  next();
})

export default router;