<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom">
    <div class="container-xl">
      <a class="navbar-brand fw-bold text-primary" href="/"><i class="bi bi-life-preserver"></i> Support</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample09"
        aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarsExample09">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link active" to="/">My Tickets</router-link>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li v-if="isRT" class="nav-item">
            <a href="/admin" class="nav-link"><i class="bi bi-person-bounding-box"></i></a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/account">{{
                currentUser.first_name + " " + currentUser.last_name
            }}</router-link>
          </li>
          <li class="nav-item">
            <span class="nav-link" aria-current="page" @click="logout" style="cursor: pointer"><i
                class="bi bi-power"></i></span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: "Navbar",
  components: {},
  data() {
    return {
      currentUser: process.appdata.user,
    };
  },
  computed: {
    isRT: () => {
      return (
        process.appdata.user.rootOrganization &&
        process.env.MIX_RT_NUMBER ===
        process.appdata.user.rootOrganization.company_number
      );
    },
  },
  methods: {
    logout() {
      process.appdata.logout();
    },
  },
};
</script>
