<template>
  <div class="container p-0">
    <div
      class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      "
    >
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item active text-dark" aria-current="page">
                Tickets
              </li>
            </ol>
          </nav>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text bg-white border-end-0"
              ><i class="bi bi-search"></i
            ></span>
            <input
              v-model="filter.name"
              type="text"
              class="form-control border-start-0"
              placeholder="Search..."
              v-on:keyup.enter="fetch_tickets()"
            />
          </div>
        </div>
        <div class="">
          <router-link class="btn btn-dark" to="/admin/newticket"
            >+
            <span class="d-none d-xl-inline d-xxl-inline">New Ticket</span>
          </router-link>
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text border-end-0 bg-white"
              ><i class="bi bi-circle"></i
            ></span>
            <select
              v-model="filter.status"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 125px"
            >
              <option disabled="">Status</option>
              <option value="">All Tickets</option>
              <option value="open">All Except Closed</option>
              <option value="submitted">Submitted</option>
              <option value="accepted">Accepted</option>
              <option value="working">Working</option>
              <option value="resolved">Resolved</option>
              <option value="closed">Closed</option>
            </select>
          </div>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text border-end-0 bg-white"
              ><i class="bi bi-window-sidebar"></i
            ></span>
            <select
              v-model="filter.service"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 125px"
            >
              <option disabled="">Service</option>
              <option value="">All Services</option>
              <option
                v-for="service in services"
                v-bind:key="service.id"
                :value="service.id"
              >
                {{ service.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text border-end-0 bg-white"
              ><i class="bi bi-building"></i
            ></span>
            <select
              v-model="filter.organisation"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 125px"
            >
              <option disabled="">Organisation</option>
              <option value="">All</option>
              <option
                v-for="organisation in organisations"
                v-bind:key="organisation.id"
                :value="organisation.id"
              >
                {{ organisation.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text border-end-0 bg-white"
              ><i class="bi bi-person-circle"></i
            ></span>
            <select
              v-model="filter.creator"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 125px"
            >
              <option disabled="">User</option>
              <option value="">All</option>
              <option
                v-for="creator in creators"
                v-bind:key="creator.id"
                :value="creator.id"
              >
                {{ creator.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text border-end-0 bg-white"
              ><i class="bi bi-people-fill"></i
            ></span>
            <select
              v-model="filter.department"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 125px"
            >
              <option disabled="">Department</option>
              <option value="">All</option>
              <option
                v-for="department in departments"
                v-bind:key="department.id"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text border-end-0 bg-white"
              ><i class="bi bi-menu-button-wide-fill"></i
            ></span>
            <select
              v-model="filter.category"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 125px"
            >
              <option disabled="">Categories</option>
              <option value="">All</option>
              <option
                v-for="category in categories"
                v-bind:key="category.id"
                value="1"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <div class="input-group">
            <span class="input-group-text border-end-0 bg-white"
              ><i class="bi bi-filter-circle"></i
            ></span>
            <select
              v-model="filter.sort"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 125px"
            >
              <option disabled="">Sort By</option>
              <option value="date_asc">Date ASC</option>
              <option value="date_desc">Date DESC</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card mb-2">
        <div v-if="!tickets || 0 === tickets.length" class="card-body">
          <div class="text-center">
            <img src="/images/empty-tickets.svg" class="img-fluid py-5" />
          </div>
        </div>

        <div v-else>
          <div class="card-header bg-light">
            <div class="row">
              <div class="col-md-4">Ticket / Category / Service</div>
              <div class="col-md-6 text-center">Created By / Elapsed time</div>
              <div class="col-md-2 text-end">Created / Status</div>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <router-link
              v-for="ticket in tickets"
              v-bind:key="ticket.id"
              :to="'/admin/tickets/' + decHex(ticket.id)"
              class="list-group-item list-group-item-action"
            >
              <div class="row">
                <div class="col-md-4">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <b class="text-dark font-monospace small">{{
                        ticket.subject
                      }}</b>
                      <p class="small text-muted mb-0">
                        <span
                          class="badge bg-primary bg-opacity-25 text-primary"
                          >{{ ticket.category_name }}</span
                        >
                        <span
                          class="badge bg-white text-dark border float-end"
                          >{{ ticket.service_name }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img
                        :src="ticket.creator_avatar"
                        :alt="ticket.creator_name"
                        class="rounded-circle align-self-center"
                        width="36"
                        height="36"
                        onerror="this.src='/images/person-circle.svg';"
                      />
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <b class="text-dark"
                        >{{ ticket.creator_name }}
                        <small>
                          <span
                            class="
                              badge
                              bg-primary bg-opacity-25
                              text-primary
                              d-block
                              float-end
                            "
                            >{{ elapsedTime(ticket.elapsed_time) }}</span
                          >
                        </small>
                      </b>
                      <p class="small text-muted mb-0">
                        {{ ticket.organisation_name }}
                        <span
                          class="
                            badge
                            bg-warning bg-opacity-25
                            text-warning
                            float-end
                          "
                          >{{
                            capitalizeText(ticket.organisation_type, true)
                          }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 ms-3">
                      <p class="small mb-0">
                        <span class="badge bg-white text-dark border">{{
                          ticket.department
                        }}</span>
                      </p>
                    </div>
                    <div class="flex-grow-0 ms-3">
                      <div class="text-end">
                        <span
                          v-if="'submitted' === ticket.status"
                          class="
                            badge
                            bg-white
                            text-danger
                            border border-danger
                            d-block
                          "
                          >{{ timeAgo(ticket.created_at) }}</span
                        >
                        <span
                          v-if="'accepted' === ticket.status"
                          class="
                            badge
                            bg-white
                            text-warning
                            border border-warning
                            d-block
                          "
                          >{{ timeAgo(ticket.created_at) }}</span
                        >
                        <span
                          v-if="'working' === ticket.status"
                          class="
                            badge
                            bg-white
                            text-info
                            border border-info
                            d-block
                          "
                          >{{ timeAgo(ticket.created_at) }}</span
                        >
                        <span
                          v-if="'resolved' === ticket.status"
                          class="
                            badge
                            bg-white
                            text-primary
                            border border-primary
                            d-block
                          "
                          >{{ timeAgo(ticket.created_at) }}</span
                        >
                        <span
                          v-if="'closed' === ticket.status"
                          class="
                            badge
                            bg-white
                            text-success
                            border border-success
                            d-block
                          "
                          >{{ timeAgo(ticket.created_at) }}</span
                        >
                        <span
                          v-if="'submitted' === ticket.status"
                          class="badge bg-danger bg-opacity-25 text-danger"
                          style="width: 90px"
                          >Submitted</span
                        >
                        <span
                          v-if="'accepted' === ticket.status"
                          class="badge bg-warning bg-opacity-25 text-warning"
                          style="width: 90px"
                          >Accepted</span
                        >
                        <span
                          v-if="'working' === ticket.status"
                          class="badge bg-info bg-opacity-25 text-info"
                          style="width: 90px"
                          >Working</span
                        >
                        <span
                          v-if="'resolved' === ticket.status"
                          class="badge bg-primary bg-opacity-25 text-primary"
                          style="width: 90px"
                          >Resolved</span
                        >
                        <span
                          v-if="'closed' === ticket.status"
                          class="badge bg-success bg-opacity-25 text-success"
                          style="width: 90px"
                          >Closed</span
                        >
                        <span
                          v-if="'onhold' === ticket.status"
                          class="badge bg-muted bg-opacity-25 text-muted"
                          style="width: 90px"
                          >Customer hold</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <pagination
          v-if="paginator.current_page"
          :records="paginator.total_items"
          :per-page="10"
          v-model="current_page"
          :options="paginatorOptions"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "v-pagination-3";

import Storage from "../../helpers/storage.js";
import Utils from "../../helpers/utils";

import RTPagination from "../../components/RTPagination.vue";

export default {
  name: "AdminTickets",
  components: { Pagination, RTPagination },
  data() {
    return {
      paginatorOptions: {
        template: RTPagination,
      },
      filter: {
        status: "",
        service: "",
        organisation: "",
        creator: "",
        department: "",
        category: "",
        name: "",
        sort: "date_desc",
      },
      paginator: {},
      services: [],
      organisations: [],
      creators: [],
      categories: [],
      departments: [],
      tickets: [],
      current_page: 1,
    };
  },
  mounted() {
    let searchStatus = window.location.search.match(/status=(.+)/);
    if (null !== searchStatus) {
      this.filter.status = searchStatus[1];
    }

    this.fetch_services();
    this.fetch_organisations();
    this.fetch_categories();
    this.fetch_departments();
    this.fetch_users();
    this.fetch_tickets();
  },
  methods: {
    fetch_services() {
      axios
        .get(process.env.MIX_RT_UMS_URL + "/services?items=1000", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.services = response.data.data.sort((one, two) => {
            return one.name > two.name;
          });
        });
    },
    fetch_organisations() {
      axios
        .get(process.env.MIX_RT_UMS_URL + "/organizations?items=1000", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.organisations = response.data.data.sort((one, two) => {
            return one.name > two.name;
          });
        });
    },
    fetch_categories() {
      axios
        .get("/api/v1/categories", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.categories = response.data;
        });
    },
    fetch_departments() {
      axios
        .get("/api/v1/departments/list", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.departments = response.data;
        });
    },
    fetch_users() {
      axios
        .get("/api/v1/users", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.creators = response.data.sort((one, two) => {
            return one.name > two.name;
          });
        });
    },
    fetch_tickets() {
      let url =
        "/api/v1/tickets?page=" +
        this.current_page +
        "&sort=" +
        this.filter.sort;

      if ("" !== this.filter.status) {
        url += "&status=" + this.filter.status;
      }

      if ("" !== this.filter.service) {
        url += "&service=" + this.filter.service;
      }

      if ("" !== this.filter.organisation) {
        url += "&organisation=" + this.filter.organisation;
      }

      if ("" !== this.filter.creator) {
        url += "&creator=" + this.filter.creator;
      }

      if ("" !== this.filter.department) {
        url += "&department=" + this.filter.department;
      }

      if ("" !== this.filter.category) {
        url += "&category=" + this.filter.category;
      }

      if ("" !== this.filter.name) {
        url += "&name=" + encodeURIComponent(this.filter.name);
      }

      axios
        .get(url, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.tickets = response.data.data;

          this.paginator = response.data.paginator;
        });
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    elapsedTime(seconds) {
      return Utils.elapsedTime(seconds);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    capitalizeText(text) {
      return Utils.capitalizeText(text);
    },
    decHex(number) {
      return Utils.decHex(number);
    },
    hexDec(string) {
      return Utils.hexDec(string);
    },
  },
  watch: {
    current_page: function () {
      this.fetch_tickets();
    },
    "filter.status": function () {
      this.fetch_tickets();
    },
    "filter.service": function () {
      this.fetch_tickets();
    },
    "filter.organisation": function () {
      this.fetch_tickets();
    },
    "filter.creator": function () {
      this.fetch_tickets();
    },
    "filter.department": function () {
      this.fetch_tickets();
    },
    "filter.category": function () {
      this.fetch_tickets();
    },
    "filter.sort": function () {
      this.fetch_tickets();
    },
  },
};
</script>