<template>
  <div class="container p-0">
    <div
      class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      "
    >
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item">
                <router-link to="/admin/tickets" class="text-decoration-none"
                  >Tickets</router-link
                >
              </li>
              <li class="breadcrumb-item active text-dark">New Ticket</li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="card mb-2">
            <div class="card-header bg-white">
              <i class="bi bi-life-preserver me-2"></i>
              New ticket
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label">Subject</label>
                <input
                  v-model="subject"
                  type="text"
                  class="form-control"
                  v-on:keyup="validate"
                />
                <div class="form-text">
                  Please try to be short and descriptive
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Organisation</label>
                    <select
                      v-model="organisation"
                      class="form-select"
                      v-on:change="validate"
                    >
                      <option value="">Select an Option</option>
                      <option value="n/a">Other</option>
                      <option
                        v-for="organisation in organisations"
                        v-bind:key="organisation.id"
                        :value="organisation.id"
                      >
                        {{ organisation.name }}
                      </option>
                    </select>
                    <div class="form-text">
                      The Organisation that has the problem
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Site</label>
                    <select
                      v-model="site"
                      class="form-select"
                      aria-label="Default select example"
                      v-on:change="validate"
                    >
                      <option value="">Select an Option</option>
                      <option value="n/a">Other</option>
                      <option
                        v-for="site in sites"
                        v-bind:key="site.id"
                        :value="site.id"
                      >
                        {{ site.name }}
                      </option>
                    </select>
                    <div class="form-text">The Site where the problem is</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Service</label>
                    <select
                      v-model="service"
                      class="form-select"
                      aria-label="Default select example"
                      v-on:change="validate"
                    >
                      <option value="">Select an Option</option>
                      <option value="n/a">Other</option>
                      <option
                        v-for="service in services"
                        v-bind:key="service.id"
                        :value="service.id"
                      >
                        {{ service.name }}
                      </option>
                    </select>
                    <div class="form-text">What Service is this related to</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Category</label>
                    <select
                      v-model="category"
                      class="form-select"
                      aria-label="Default select example"
                      v-on:change="validate"
                    >
                      <option value="">Select an Option</option>
                      <option
                        v-for="category in categories"
                        v-bind:key="category.id"
                        :value="category.id"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                    <div class="form-text">What type of problem is this</div>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <label class="form-label">Description</label>
                <textarea
                  v-model="description"
                  class="form-control"
                  rows="5"
                  v-on:keyup="validate"
                ></textarea>
              </div>
              <div class="d-grid gap-2">
                <button
                  type="button w-100"
                  :disabled="!valid_input"
                  class="btn btn-primary"
                  @click="add_ticket"
                >
                  Create Ticket
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-2">
            <div class="card-header bg-white">
              <i class="bi bi-cpu me-2"></i>
              Devices
              <span class="float-end text-muted">Optional</span>
            </div>
            <ul class="list-group list-group-flush">
              <li
                v-for="device in devices"
                v-bind:key="device.eui"
                class="list-group-item d-flex align-items-center bg-light"
              >
                <div class="flex-shrink-0">
                  <i
                    v-if="'sensor' == device.type"
                    class="bi bi-thermometer-half fs-4"
                  ></i>
                  <i v-if="'module' == device.type" class="bi bi-cpu fs-4"></i>
                  <i
                    v-if="'gateway' == device.type"
                    class="bi bi-wifi fs-4"
                  ></i>
                </div>
                <div class="flex-grow-1 ms-3">
                  <b class="text-dark font-monospace small">{{ device.eui }}</b>
                  <p
                    v-if="'sensor' == device.type"
                    class="small text-muted mb-0"
                  >
                    Temperature sensor ({{ device.manufactured_year }})
                  </p>
                  <p
                    v-if="'module' == device.type"
                    class="small text-muted mb-0"
                  >
                    Module ({{ device.manufactured_year }})
                  </p>
                  <p
                    v-if="'gateway' == device.type"
                    class="small text-muted mb-0"
                  >
                    Gateway ({{ device.manufactured_year }})
                  </p>
                </div>
                <div class="flex-shrink-0">
                  <button
                    type="button"
                    class="btn btn-link p-0"
                    @click="remove_device(device.eui)"
                  >
                    <i
                      class="bi bi-x-circle fs-5 text-danger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title="Remove"
                    ></i>
                  </button>
                </div>
              </li>
            </ul>
            <div class="card-body py-2">
              <div class="row g-2">
                <div class="col-md-7">
                  <div class="input-group">
                    <button
                      class="btn border border-end-0"
                      type="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title="Scan QR Code"
                    >
                      <i class="bi bi-qr-code-scan"></i>
                    </button>
                    <input
                      v-model="new_device"
                      type="text"
                      class="form-control font-monospace border-start-0"
                      placeholder="Device ID"
                      maxlength="16"
                      minlength="16"
                    />
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="d-grid gap-2">
                    <button
                      type="button w-100"
                      class="btn btn-outline-dark"
                      @click="add_device()"
                    >
                      <i class="bi bi-plus"></i> Add Device
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-header bg-white">
              <i class="bi bi-file-earmark-zip me-2"></i>
              Files
              <span class="float-end text-muted">Optional</span>
            </div>
            <ul class="list-group list-group-flush">
              <li
                v-for="file in files"
                v-bind:key="file.hash"
                class="list-group-item d-flex align-items-center bg-light"
              >
                <div class="flex-shrink-0">
                  <i
                    v-if="'pdf' == file.extension"
                    class="bi bi-file-pdf fs-4"
                  ></i>
                  <i
                    v-else-if="'zip' == file.extension"
                    class="bi bi-file-zip fs-4"
                  ></i>
                  <i
                    v-else-if="
                      'png' == file.extension ||
                      'jpg' == file.extension ||
                      'gif' == file.extension ||
                      'jpeg' == file.extension
                    "
                    class="bi bi-file-image fs-4"
                  ></i>
                  <i v-else class="bi bi-file fs-4"></i>
                </div>
                <div class="flex-grow-1 ms-3">
                  <b class="text-dark font-monospace small">{{ file.name }}</b>
                  <p class="small text-muted mb-0">
                    {{ Math.floor(file.size / 1000) }} KB
                  </p>
                </div>
                <div class="flex-shrink-0">
                  <button
                    type="button"
                    class="btn btn-link p-0"
                    @click="remove_file(file.hash)"
                  >
                    <i
                      class="bi bi-x-circle fs-5 text-danger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-bs-original-title="Remove"
                    ></i>
                  </button>
                </div>
              </li>
            </ul>
            <div class="card-body py-4 bg-light text-center position-relative">
              <div
                class="d-inline-block bg-dark rounded-circle d-flex mx-auto"
                style="height: 45px; width: 45px"
              >
                <i
                  class="
                    bi bi-paperclip
                    fs-5
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-white
                  "
                ></i>
              </div>
              <div class="d-inline-block d-flex">
                <div class="align-self-center mx-auto mt-2">
                  <b class="text-muted small">Drag and drop file here</b>
                  <p class="small text-muted">Supported: PDF, JPG, PNG</p>

                  <input
                    type="file"
                    id="modalDropTarget"
                    :onchange="add_file"
                  />
                </div>
              </div>
            </div>
            <div class="card-body py-2">
              <div class="d-grid gap-2">
                <button type="button w-100" class="btn btn-outline-dark">
                  <i class="bi bi-paperclip"></i> Attach Files
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";

export default {
  name: "AdminNewTicket",
  components: {},
  data() {
    return {
      subject: "",
      description: "",
      organisation: "",
      organisations: [],
      site: "",
      sites: [],
      service: "",
      services: [],
      category: "",
      categories: [],
      new_device: "",
      devices: {},
      files: {},
      valid_input: false,
    };
  },
  mounted() {
    this.fetch_organisations();

    this.fetch_categories();
  },
  methods: {
    fetch_organisations() {
      axios
        .get(process.env.MIX_RT_UMS_URL + "/admin/organizations?status=active&items=1000", {
          headers: {
            Authorization: process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.organisations = response.data.data.sort((one, two) => {
            return one.name > two.name;
          });
        });
    },
    fetch_sites() {
      if ("" === this.organisation) {
        return;
      }

      if ("n/a" === this.organisation) {
        this.site = "";
        this.sites = [];
        return;
      }

      axios
        .get(
          process.env.MIX_RT_UMS_URL +
            "/organizations/" +
            this.organisation +
            "/sites?items=1000",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.sites = response.data.data.sort((one, two) => {
            return one.name > two.name;
          });
        });
    },

    fetch_services() {
      if ("" === this.organisation) {
        return;
      }

      if ("n/a" === this.organisation) {
        this.service = "";
        this.services = [];
        return;
      }

      axios
        .get(
          process.env.MIX_RT_UMS_URL +
            "/organizations/" +
            this.organisation +
            "/services?items=1000",
          {
            headers: {
              Authorization: process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.services = response.data.data;
        });
    },

    fetch_categories() {
      axios
        .get("/api/v1/categories/list", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.categories = response.data;
        });
    },

    add_device() {
      axios
        .get("/api/v1/tickets/check_device/" + this.new_device, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (response.data.eui) {
            this.devices[response.data.eui] = response.data;

            this.new_device = "";
          } else if (response.data.error) {
            this.emitter.emit("popup_alert", {
              type: "danger",
              text: response.data.error,
            });
          }
        });
    },

    remove_device(eui) {
      delete this.devices[eui];
    },

    add_file(ev) {
      var app = this;

      var fileObj = ev.target.files[0],
        reader = new FileReader();

      reader.onloadend = function (blob) {
        var data = new FormData();
        data.append("image", fileObj);

        axios
          .post("/api/v1/tickets/file", data, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          })
          .then(
            (response) => {
              app.emitter.emit("popup_alert", {
                type: "success",
                text: "File uploaded",
              });

              if (response.data.hash) {
                app.files[response.data.hash] = response.data;
              } else if (response.data.error) {
                app.emitter.emit("popup_alert", {
                  type: "danger",
                  text: response.data.error,
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      };

      var blob = fileObj.slice(0, fileObj.size);
      reader.readAsBinaryString(blob);
    },

    remove_file(hash) {
      delete this.files[hash];
    },

    validate() {
      this.valid_input =
        "" !== this.subject &&
        "" !== this.description &&
        "" !== this.organisation &&
        "" !== this.site &&
        "" !== this.service &&
        "" !== this.category;
    },

    add_ticket() {
      var app = this;

      let data = {
        subject: this.subject,
        description: this.description,
        organisation: this.organisation,
        site: this.site,
        service: this.service,
        category: this.category,
        devices: this.devices,
        files: this.files,
        ums_token: process.appdata.tokens.access_token,
      };

      axios
        .post("/api/v1/tickets", data, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then(function (response) {
          app.emitter.emit("ticket_created");

          app.emitter.emit("popup_alert", {
            type: "success",
            text: "Ticket was successfully created!",
          });

          setTimeout(() => {
            app.$router.push("/admin/tickets");
          }, 2500);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  watch: {
    organisation: function () {
      this.site = "";
      this.service = "";

      this.fetch_sites();
      this.fetch_services();
    },
  },
};
</script>