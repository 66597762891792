<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Assign Ticket to Yourself</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-info text-center">
                Are you sure you to assign this ticket to yourself?
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <div class="btn btn-info w-100" @click="selfassign_ticket">
                Confirm
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "TicketSelfAssign",
  props: ["id"],
  data() {
    return {
      ticket_id: "",
    };
  },
  mounted() {
    this.emitter.on("ticket_details_loaded", (data) => {
      this.ticket_id = data.details.id;
    });
  },
  methods: {
    selfassign_ticket() {
      axios
        .get("/api/v1/tickets/" + this.ticket_id + "/selfassign", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          if (200 === response.status) {
            let selfassignTicketModal = bootstrap.Modal.getInstance(
              document.getElementById(this.id)
            );
            selfassignTicketModal.hide();

            this.emitter.emit("ticket_details_updated", this.ticket);

            this.emitter.emit("popup_alert", {
              type: "success",
              text: "Ticket was successfully assigned to yourself!",
            });
          } else {
            process.appdata.umslogin();
          }
        });
    },
  },
};
</script>