<template>
  <div class="container p-0">
    <div
      class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      "
    >
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item active text-dark" aria-current="page">
                Canned replies
              </li>
            </ol>
          </nav>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text bg-white border-end-0"
              ><i class="bi bi-search"></i
            ></span>
            <input
              v-model="filter.name"
              type="text"
              class="form-control border-start-0"
              placeholder="Search..."
              v-on:keyup.enter="fetch_replies()"
            />
          </div>
        </div>
        <div class="">
          <router-link class="btn btn-dark" to="/admin/newreply"
            >+
            <span class="d-none d-xl-inline d-xxl-inline"
              >Add canned reply</span
            ></router-link
          >
        </div>
      </div>

      <div class="d-flex mb-2">
        <div class="me-2">
          <div class="input-group">
            <span
              class="input-group-text border-end-0 bg-white"
              id="basic-addon1"
              ><i class="bi bi-check-circle"></i
            ></span>
            <select
              v-model="filter.shared"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 200px"
            >
              <option disabled="">Sharing</option>
              <option value="">All</option>
              <option value="1">Shared</option>
              <option value="0">Not Shared</option>
            </select>
          </div>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span
              class="input-group-text border-end-0 bg-white"
              id="basic-addon1"
              ><i class="bi bi-person-circle"></i
            ></span>
            <select
              v-model="filter.creator"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 200px"
            >
              <option disabled="">User</option>
              <option value="">All Users</option>
              <option
                v-for="creator in creators"
                v-bind:key="creator.id"
                :value="creator.id"
              >
                {{ creator.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="card mb-2">
        <div v-if="!replies || 0 === replies.length" class="card-body">
          <div class="text-center">
            <img src="/images/empty-replies.svg" class="img-fluid py-5" />
          </div>
        </div>

        <div v-else class="list-group list-group-flush">
          <router-link
            v-for="reply in replies"
            v-bind:key="reply.id"
            :to="'/admin/replies/' + reply.id"
            class="list-group-item list-group-item-action"
          >
            <div class="d-flex align-items-center">
              <div
                class="
                  flex-shrink-0
                  bg-white
                  border
                  bg-opacity-25
                  rounded-circle
                  d-flex
                "
                style="height: 45px; width: 45px"
              >
                <i
                  class="
                    bi bi-chat-square-dots-fill
                    fs-5
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-dark
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark small">{{ reply.name }}</b>
                <p v-if="0 === reply.shared" class="small mb-0 text-danger">
                  <i class="bi bi-check-circle"></i> This reply is not shared
                  with everyone
                </p>
                <p v-else class="small mb-0 text-success">
                  <i class="bi bi-check-circle"></i> This reply is shared with
                  everyone
                </p>
              </div>
              <div class="flex-shrink-0">
                <img
                  :src="reply.avatar"
                  :alt="reply.creator_name"
                  class="rounded-circle align-self-center"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="reply.creator_name"
                  width="36"
                  height="36"
                  onerror="this.src='/images/person-circle.svg';"
                />
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";

export default {
  name: "AdminReplies",
  components: {},
  data() {
    return {
      filter: {
        shared: "",
        creator: "",
        name: "",
      },
      replies: [],
    };
  },
  mounted() {
    this.fetch_users();
    this.fetch_replies();
  },
  methods: {
    fetch_users() {
      axios
        .get("/api/v1/users", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.creators = response.data.sort((one, two) => {
            return one.name > two.name;
          });
        });
    },
    fetch_replies() {
      axios
        .get(
          "/api/v1/replies?shared=" +
            this.filter.shared +
            "&creator=" +
            this.filter.creator +
            "&name=" +
            encodeURIComponent(this.filter.name),
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.replies = response.data;
        });
    },
  },
  watch: {
    "filter.shared": function () {
      this.fetch_replies();
    },
    "filter.creator": function () {
      this.fetch_replies();
    },
  },
};
</script>