<template>
  <div class="card">
    <div class="card-header bg-light d-flex">
      <i class="bi bi-app-indicator me-2"></i>
      Overview
      <span class="ms-auto text-muted small">Active</span>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-circle me-3"></i>Profile Photo:
          </div>
          <div class="w-75 py-2 px-3">
            <img :src="me.picture" :alt="me.full_name" class="rounded-circle align-self-center" width="21" height="21"
              onerror="this.src='/images/person-circle.svg';" />
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#userAvatar">
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge me-3"></i>First Name:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.first_name }}

            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#userDetails">
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge me-3"></i>Last Name:
          </div>
          <div class="w-75 py-2 px-3">{{ me.last_name }}

            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#userDetails">
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-person-badge-fill me-3"></i>Job Title:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.job_title }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#userDetails">
              Update
            </button>
          </div>
        </li>
        <li class="list-group-item d-flex p-0 border-0">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-telephone me-3"></i>Phone Number:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.phone_number }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#userDetails">
              Update
            </button>
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-envelope me-3"></i>Email:
          </div>
          <div class="w-75 py-2 px-3">
            {{ me.email }}
          </div>
        </li>

        <li class="list-group-item d-flex p-0 border-0 mt-1">
          <div class="w-25 py-2 px-3 bg-light">
            <i class="bi bi-globe me-3"></i>Timezone:
          </div>
          <div class="w-75 py-2 px-3">
            {{ readableTimezone(me.timezone) }}
            <button type="button" class="btn btn-link p-0 float-end text-decoration-none" data-bs-toggle="modal"
              data-bs-target="#userTimezone">
              Update
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <ModalMyDetails id="userDetails" />
  <ModalMyTimezone id="userTimezone" />
  <ModalUserAvatar id="userAvatar" />
</template>

<script>
import Utils from "../../helpers/utils";
import Storage from "../../helpers/storage";

import ModalMyDetails from "../../components/modals/MyDetails.vue";
import ModalMyTimezone from "../../components/modals/MyTimezone.vue";
import ModalUserAvatar from "../../components/modals/UserAvatar.vue";

export default {
  name: "MyAccountOverview",
  components: { ModalMyDetails, ModalMyTimezone, ModalUserAvatar },
  data() {
    return {
      me: process.appdata.user,
      user_agent: {
        device: {},
        browser: {},
        os: {},
        ua: "",
        device_type: "",
      },
    };
  },
  mounted() {
    this.emitter.on("user_avatar_updated", (data) => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Your profile picture was successfully updated!",
      });

      process.appdata.getme(() => {
        window.location.reload();
      });
    });

    this.emitter.on("user_details_updated", (data) => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Your details were successfully updated!",
      });

      process.appdata.getme(() => {
        window.location.reload();
      });
    });

    this.emitter.on("user_timezone_updated", (data) => {
      this.emitter.emit("popup_alert", {
        type: "success",
        text: "Your timezone was successfully updated!",
      });

      process.appdata.getme(() => {
        window.location.reload();
      });
    });

    this.emitter.emit("user_details_loaded", this.me);
  },
  methods: {
    capitalizeText(text, allwords) {
      return Utils.capitalizeText(text, allwords);
    },
    readableTimezone(timezoneCode) {
      return Utils.readableTimezone(timezoneCode);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
  },
};
</script>
