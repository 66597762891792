<template>
  <div class="container p-0">
    <div
      class="
        p-2
        py-md-3 py-lg-3 py-xl-3 py-xxl-3
        px-md-5 px-lg-5 px-xl-5 px-xxl-5
      "
    >
      <div class="d-flex">
        <div class="flex-grow-1 me-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb fs-5 fw-light mt-1">
              <li class="breadcrumb-item active text-dark" aria-current="page">
                Departments
              </li>
            </ol>
          </nav>
        </div>
        <div class="me-2">
          <div class="input-group">
            <span class="input-group-text bg-white border-end-0"
              ><i class="bi bi-search"></i
            ></span>
            <input
              v-model="filter.name"
              type="text"
              class="form-control border-start-0"
              placeholder="Search..."
              v-on:keyup.enter="fetch_departments()"
            />
          </div>
        </div>
        <div class="">
          <button
            type="button"
            class="btn btn-dark"
            data-bs-toggle="modal"
            data-bs-target="#newDepartment"
          >
            +
            <span class="d-none d-xl-inline d-xxl-inline">Add Department</span>
          </button>
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="me-2">
          <div class="input-group">
            <span
              class="input-group-text border-end-0 bg-white"
              id="basic-addon1"
              ><i class="bi bi-people"></i
            ></span>
            <select
              v-model="filter.category"
              class="form-select bg-white border-start-0 ps-0"
              style="max-width: 200px"
            >
              <option disabled="">Category</option>
              <option value="">All Categories</option>
              <option
                v-for="category in categories"
                v-bind:key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="card mb-2">
        <div v-if="!departments || 0 === departments.length" class="card-body">
          <div class="text-center">
            <img src="/images/empty-departments.svg" class="img-fluid py-5" />
          </div>
        </div>

        <div v-else class="list-group list-group-flush">
          <router-link
            v-for="department in departments"
            v-bind:key="department.id"
            :to="'/admin/departments/' + department.id"
            class="list-group-item list-group-item-action"
          >
            <div class="d-flex align-items-center">
              <div
                class="
                  flex-shrink-0
                  bg-white
                  border
                  bg-opacity-25
                  rounded-circle
                  d-flex
                "
                style="height: 45px; width: 45px"
              >
                <i
                  class="
                    bi bi-people-fill
                    fs-5
                    rounded-circle
                    align-self-center
                    mx-auto
                    text-dark
                  "
                ></i>
              </div>
              <div class="flex-grow-1 ms-3">
                <b class="text-dark small">{{ department.name }}</b>
                <p class="small text-muted mb-0">
                  <span
                    v-for="category in department.categories"
                    v-bind:key="category.id"
                    class="badge bg-primary bg-opacity-25 text-primary me-1"
                    >{{ category.name }}</span
                  >
                </p>
              </div>
              <div class="flex-shrink-0">
                <img
                  v-for="member in department.members"
                  v-bind:key="member.id"
                  :src="member.avatar"
                  :alt="member.name"
                  class="rounded-circle align-self-center"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="member.name"
                  width="36"
                  height="36"
                />
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <ModalNewDepartment id="newDepartment" />
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

import ModalNewDepartment from "../../components/modals/NewDepartment.vue";

export default {
  name: "AdminDepartments",
  components: { ModalNewDepartment },
  data() {
    return {
      filter: {
        category: "",
        name: "",
      },
      departments: [],
      categories: [],
    };
  },
  mounted() {
    this.emitter.on("department_created", (data) => {
      this.fetch_departments();
    });

    this.fetch_categories();
    this.fetch_departments();
  },
  methods: {
    fetch_categories() {
      axios
        .get("/api/v1/categories/list/", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.categories = response.data;
        });
    },

    fetch_departments() {
      axios
        .get(
          "/api/v1/departments?category=" +
            this.filter.category +
            "&name=" +
            encodeURIComponent(this.filter.name),
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.departments = response.data;
        });
    },
  },
  watch: {
    "filter.category": function () {
      this.fetch_departments();
    },
  },
};
</script>