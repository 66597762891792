<template>
  <Navbar v-if="!isAdminPage" />
  <router-view />
  <Footer v-if="!isAdminPage" />
  <PopupAlert type="alert" text="That was cool!" />
</template>

<script>
import Footer from "./components/Footer.vue";
import Navbar from "./components/Navbar.vue";
import PopupAlert from "./components/PopupAlert.vue";

export default {
  name: "App",
  components: { Navbar, Footer, PopupAlert },
  mounted() {},
  data() {
    return {
      isAdminPage: -1 !== window.location.href.indexOf("admin") ? true : false,
    };
  },
};
</script>