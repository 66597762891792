<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change ticket assignee</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="assignee" class="form-label">Ticket assignee</label
              ><select class="form-control" v-model="assignee_id" id="assignee">
                <option value="">Please select</option>
                <option
                  v-for="user in users"
                  v-bind:key="user.id"
                  :value="user.id"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                @click="update_assignee"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage.js";
import Utils from "../../helpers/utils.js";

export default {
  name: "TicketAssignee",
  props: ["id"],
  data() {
    return {
      ticket_id: "",
      assignee_id: "",
      category_id: "",
      users: [],
    };
  },
  mounted() {
    this.emitter.on("ticket_details_loaded", (data) => {
      this.ticket_id = this.hexDec(this.$route.params.ticket_id);
      this.assignee_id = data.details
        ? null === data.details.assignee
          ? ""
          : data.details.assignee
        : "";
      this.category_id = data.details ? data.details.category : "";

      this.fetch_users();
    });
  },
  methods: {
    fetch_users() {
      axios
        .get("/api/v1/users?category=" + this.category_id, {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.users = response.data;
        });
    },
    update_assignee() {
      axios
        .patch(
          "/api/v1/tickets/" + this.ticket_id + "/assign",
          { assignee: this.assignee_id },
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          let assignTicketModal = bootstrap.Modal.getInstance(
            document.getElementById(this.id)
          );
          assignTicketModal.hide();

          this.emitter.emit("ticket_details_updated", this.ticket);

          this.emitter.emit("popup_alert", {
            type: "success",
            text: "Ticket was successfully assigned!",
          });
        });
    },

    elapsedTime(dateString) {
      return Utils.elapsedTime(dateString);
    },
    timeAgo(dateString) {
      return Utils.timeAgo(dateString);
    },
    localMachineTime(dateString) {
      return Utils.localMachineTime(dateString);
    },
    decHex(number) {
      return Utils.decHex(number);
    },
    hexDec(string) {
      return Utils.hexDec(string);
    },
  },
  watch: {},
};
</script>