<template>
  <Breadcrumbs
    :breaditems="[
      { title: 'My Tickets', link: '/' },
      { title: 'Ticket ' + decHex(ticket.id) },
    ]"
  />

  <div class="container-xl">
    <div class="row g-2">
      <div class="col-md-3 mt-2">
        <div class="card mb-2">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <b class="text-dark font-monospace"
                  >Ticket {{ decHex(ticket.id) }}</b
                >
                <p class="small text-muted mb-0">
                  <span
                    class="badge bg-primary bg-opacity-25 text-primary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Elapsed Time"
                    >{{ elapsedTime(ticket.response_time) }}</span
                  >
                </p>
              </div>
              <div class="flex-grow-1">
                <div class="float-end align-self-center mx-auto small">
                  <span
                    v-if="'submitted' === ticket.status"
                    class="badge bg-danger bg-opacity-25 text-danger"
                    >Submitted</span
                  >
                  <span
                    v-if="'accepted' === ticket.status"
                    class="badge bg-warning bg-opacity-25 text-warning"
                    >Accepted</span
                  >
                  <span
                    v-if="'working' === ticket.status"
                    class="badge bg-info bg-opacity-25 text-info"
                    >Working</span
                  >
                  <span
                    v-if="'resolved' === ticket.status"
                    class="badge bg-primary bg-opacity-25 text-primary"
                    >Resolved</span
                  >
                  <span
                    v-if="'closed' === ticket.status"
                    class="badge bg-success bg-opacity-25 text-success"
                    >Closed</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <router-link
              :to="'/tickets/' + decHex(ticket.id)"
              class="
                list-group-item list-group-item-action
                d-flex
                gap-2
                align-items-center
              "
              :class="'discussion' === active_menu ? 'active ' : ''"
              @click="setActiveMenu('discussion')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-chat-right-text"></i>
                </div>
                <div class="flex-grow-1 ms-3">Discussion</div>
              </div>
            </router-link>
            <router-link
              :to="'/tickets/' + decHex(ticket.id) + '/statushistory'"
              class="
                list-group-item list-group-item-action
                d-flex
                gap-2
                align-items-center
              "
              :class="'statushistory' === active_menu ? 'active ' : ''"
              @click="setActiveMenu('statushistory')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-clock"></i>
                </div>
                <div class="flex-grow-1 ms-3">Status History</div>
              </div>
            </router-link>
            <router-link
              :to="'/tickets/' + decHex(ticket.id) + '/information'"
              class="
                list-group-item list-group-item-action
                d-flex
                gap-2
                align-items-center
              "
              :class="'information' === active_menu ? 'active ' : ''"
              @click="setActiveMenu('information')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-file-text"></i>
                </div>
                <div class="flex-grow-1 ms-3">Information</div>
              </div>
            </router-link>
            <router-link
              :to="'/tickets/' + decHex(ticket.id) + '/wiki'"
              class="
                list-group-item list-group-item-action
                d-flex
                gap-2
                align-items-center
              "
              :class="'wiki' === active_menu ? 'active ' : ''"
              @click="setActiveMenu('wiki')"
            >
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="bi bi-journal-text"></i>
                </div>
                <div class="flex-grow-1 ms-3">Wiki</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../helpers/storage.js";
import Utils from "../helpers/utils.js";

import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
  name: "Ticket",
  components: { Breadcrumbs },
  data() {
    return {
      active_menu: "discussion",
      ticket: {
        id: this.hexDec(this.$route.params.ticket_id),
        response_time: "",
        status: "",
      },
    };
  },
  created() {
    this.active_menu = this.$route.meta.submenu;
  },
  mounted() {
    this.fetch_ticket_stats();
  },
  methods: {
    setActiveMenu(menu) {
      this.active_menu = menu;
    },
    fetch_ticket_stats() {
      axios
        .get("/api/v1/tickets/" + this.ticket.id + "/stats", {
          headers: {
            Authorization: Storage.get("session_token"),
            "ums-token": process.appdata.tokens.access_token,
          },
        })
        .then((response) => {
          this.ticket = response.data;
        });
    },
    decHex(number) {
      return Utils.decHex(number);
    },
    hexDec(string) {
      return Utils.hexDec(string);
    },
    elapsedTime(seconds) {
      return Utils.elapsedTime(seconds);
    },
    capitalizeText(string) {
      return Utils.capitalizeText(string, true);
    },
  },
};
</script>