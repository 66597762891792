<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Department</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Name</label>
              <input
                type="text"
                id="name"
                class="form-control"
                v-model="name"
                @keyup="validate"
              />
              <small class="text-secondary"
                ><i>Must be at least 3 characters</i></small
              >
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary w-100"
                :disabled="!valid_input"
                @click="add_department"
              >
                Add department
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Storage from "../../helpers/storage";

export default {
  name: "NewDepartment",
  props: ["id"],
  data() {
    return {
      name: "",
      valid_input: false,
    };
  },
  mounted() {},
  methods: {
    validate() {
      this.valid_input = this.name.length > 3;
    },

    add_department() {
      let app = this;

      axios
        .post(
          "/api/v1/departments/",
          {
            name: this.name,
          },
          {
            headers: {
              Authorization: Storage.get("session_token"),
              "ums-token": process.appdata.tokens.access_token,
            },
          }
        )
        .then((response) => {
          this.name = "";
          
          this.emitter.emit("department_created");

          let newDepartmentModal = bootstrap.Modal.getInstance(
            document.getElementById(this.id)
          );
          newDepartmentModal.hide();

          this.emitter.emit("popup_alert", {
            type: "success",
            text: "Department was successfully created!",
          });
        })
        .catch(function (error) {
          if (error.response) {
            let errorMsg = "";

            for (let key in error.response.data) {
              errorMsg += error.response.data[key].join(" ");
            }

            app.emitter.emit("popup_alert", {
              type: "danger",
              text: errorMsg,
            });
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>